import axios from "axios";
import Links from "@/shared/constants/Links";

/**
 *  This is the UTME Combination API hook, for handling UTME combination (C) 2021-2023
 */

const UtmeCombination = {
    /**
     * utme subject combination to be created
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.utmeCombination.create, data)
    },

    /**
     * update utme subject combination
     * @param {string} id id of utme subject 
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.utmeCombination.update + '/' + id, data)
    },

    /**
     * delete utme subject combination
     * @param {string} id utme subject to delete
     * @returns {Promise<object>}
     */
    delete: async (id) => {
        return axios.delete(Links.utmeCombination.delete + '/' + id)
    },

    /**
     * fetch all subject combination for a department
     * @param {string} department id of the department
     * @returns {Promise<any[]}>
     */
    fetch: async (department) => {
        return axios.get(Links.utmeCombination.fetch + '?department=' + department)
    }
}

export default UtmeCombination