import axios from "axios"; 
import Links from "../constants/Links"; 


/**
 * This is the Award API hook for handling Award reward
 * (C) 2021-2023
 *  
 * */

const Awards = {
    /**
     * @description Creates an award
     * @param {object} data an object containing the following properties: name, value, code
     * @returns {Promise<object>} the newly created award
     */
    create: async (data) => {
        return axios.post(Links.awards.create, data)
    },

    /**
     * @description Updates an exsising award
     * @param {string} id id of the award to update
     * @param {object} data an object containing the following properties: name, value, code
     * @returns {Promise<object>} the updated award
     */
    update: async (id, data) => {
        return axios.put(Links.awards.update + '/' + id, data)
    },

    /**
     * @description deletes and award
     * @param {string} id id of the award to delete
     * @returns {Promise<object>}
     */
    delete: async (id) => {
        return axios.delete(Links.awards.delete + '/' + id)
    },

    /**
     * Fetch all awards paginated
     * @param {string} page the page number of begin with (starts with zero as usual)
     * @param {string} size the size of the page
     * @returns {Promise<object>} 
     */
    allPaginated: async (page, size) => {
        return axios.get(Links.awards.allPaginated + '?page=' + page + '&size=' + size)
    },

    /**
     * Fetch all awards without pagination
     * @returns {Promise<object>}
     */
    all: async () => {
        return axios.get(Links.awards.all)
    }
}

export default Awards