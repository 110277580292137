<template>
  <v-row justify="center">
    <x-dialog :show="show" maxwidth="290" persistent>
      <x-card>
        <x-card-title class="text-h6 pl-5">
          <v-icon color="info" size="28" class="pl-0 ml-0 mr-3">{{ 'mdi-help-circle-outline'}}</v-icon>
          <span>{{ title || 'Confirm' }}</span>
        </x-card-title>
        <x-card-text class="mt-2">{{ message }}</x-card-text>
        <x-card-action>
          <v-spacer v-if="nsuk"></v-spacer>
          <x-button color="error" text @click="negative">
            {{ negativeText || 'Cancel'}}
          </x-button>
          <v-spacer v-if="!nsuk"></v-spacer>
          <x-button color="primary" text @click="positive">
            {{ positiveText || 'Proceed' }}
          </x-button>
        </x-card-action>
      </x-card>
    </x-dialog>
  </v-row>
</template>

<script>
import XDialog from '@ui/components/XDialog.vue';
import XCard from '@ui/components/XCard.vue';
import XCardTitle from '@ui/components/XCardTitle.vue';
import XCardText from '@ui/components/XCardText.vue';
import XCardAction from '@ui/components/XCardAction.vue';
import XButton from '@ui/widgets/XButton.vue';
import AppTypes from "@/shared/constants/apptypes";



export default {
  name: "ConfirmationBox",
  components: { XDialog, XCard, XCardTitle, XCardText, XCardAction, XButton },
  props: ['message', 'show', 'negativeText', 'positiveText', 'title', 'icon'],
  emit: ['negative', 'positive'],

  data: () => ({
    nsuk: true
  }),

  methods: {
    negative() {
      this.$emit('negative')
    },

    positive() {
      this.$emit('positive')
    }
  },

  created() {
    this.nsuk = AppTypes.CURRENT === AppTypes.NSUK
  }
}
</script>

<style scoped>

</style>
