<template>
  <v-app>
    <v-main style="height: 100vh; width: 100%; overflow-x: hidden;">
      <v-row justify="space-around" align="center">
        <!--MOBILE -->
        <!--                <v-col v-if="isMobile" style="background-color: #57430E; color: white;">-->
        <!--                    <v-container>-->
        <!--                        <div class="d-flex mx-auto">-->
        <!--                            <v-img contain width="50" height="50" src="../../resources/lafia/lafia.png" />-->
        <!--                        </div>-->

        <!--                        <p class="text-md-right text-center mt-5">-->
        <!--                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem,-->
        <!--                            assumenda ducimus voluptas dolorum ipsum corporis eius nam minus,-->
        <!--                            quidem beatae temporibus minima dolore hic quos esse non deserunt mollitia perferendis?-->
        <!--                        </p>-->
        <!--                    </v-container>-->
        <!--                </v-col>-->


        <!-- DESKTOP-->
        <!--                <v-col v-else cols="12" sm="4" md="4" style="background-color: #57430E;  color: white; height: 150vh; overflow: hidden;">-->
        <!--                    <v-container class="mt-5">-->
        <!--                        <v-img class="d-flex mx-auto" contain width="80" height="80" src="../../resources/lafia/lafia.png"/>-->
        <!--                        <p class="text-md-right  mt-5">-->
        <!--                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rem,-->
        <!--                            assumenda ducimus voluptas dolorum ipsum corporis eius nam minus,-->
        <!--                            quidem beatae temporibus minima dolore hic quos esse non deserunt mollitia perferendis?-->
        <!--                        </p>-->

        <!--                        <v-icon color="black" size="200" style="opacity: 0.2">mdi-shape</v-icon>-->
        <!--                        <v-icon class="icon" color="black" size="200" style="opacity: 0.2">mdi-shape</v-icon>-->
        <!--                    </v-container>-->
        <!--                </v-col>-->


        <v-col cols="12" md="8" sm="8" class="mt-md-12 mt-5 text-center" style="overflow-y: hidden;">
          <h2 class="mb-8">Student Verification</h2>

          <div class="d-flex mx-md-16 ml-6 mr-8 mt-5">
            <v-card @click="currentTab = item.children" v-model="tab" :id="item.value" v-for="item in base"
                    :key="item.value" outlined class="d-flex mx-md-auto ml-3 display" height="100" width="140">
              <v-card-text>
                <v-icon size="50" color="primary">{{ item.icon }}</v-icon>
                <p>{{ item.title }}</p>
              </v-card-text>
            </v-card>
          </div>

          <!-- MOUNTED CLICKED CARD -->
          <v-container>
            <transition name="fade" mode="in-out">
              <component :is="currentTab"/>
            </transition>
          </v-container>
        </v-col>
      </v-row>

      <loading-dialog :show="loading"></loading-dialog>
      <message-box :show="messageShow" :message="message" :type="messageType"
                   v-on:close-message="messageShow = !messageShow"></message-box>
    </v-main>
  </v-app>
</template>


<script>
import {mapGetters, mapActions} from "vuex";
import XCard from "ui/lafia/components/XCard.vue";
import Api from "@/main/api";
import ErrorResolver from "shared/ErrorResolver";
import Profile from "./components/Profile.vue";
import Payment from "./components/Payment.vue";
import CourseRegistration from "./components/Registration.vue";
import LoadingDialog from "ui/lafia/components/LoadingDialog.vue";
import MessageBox from "@ui/components/MessageBox";

export default {
  name: "App",
  components: {
    LoadingDialog,
    MessageBox,
    XCard,
    Payment,
    Profile,
    CourseRegistration
  },
  data: () => ({
    image: "",
    messageType: '',
    messageShow: false,
    loading: false,
    currentTab: 'profile',
    message: "",
    base: [
      {id: 1, title: 'Profile', value: 'profile', icon: 'mdi-account', children: Profile},
      {id: 2, title: 'Payment', value: 'payment', icon: 'mdi-cash', children: Payment},
      {id: 3, title: 'Registration', icon: 'mdi-account-plus', children: CourseRegistration}
    ],
    tab: null,
  }),

  methods: {
    ...mapActions(['loadGlobalConfig']),
    loadData() {
      Api.global.fetch()
          .then(res => {
            this.$store.commit('setGlobal', res.data);
          })
          .catch(err => ErrorResolver.checkErrors(this, err))
    },


    loadUser() {
      this.loading = true;
      Api.students.find(window.DATA)
          .then((res) => {
            this.$store.commit('setCurrentUser', {user: res.data});
          })
          .catch((err) => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false);
    },
  },

  computed: {
    ...mapGetters(['getGlobal', 'getUser']),
    currentComponent: function () {
      return this.currentTab = this.base[1].children;
    },

    isMobile: function () {
      return this.$vuetify.breakpoint.xs;
    }
  },

  mounted() {
    this.currentTab = this.base[0].children
    Api.schools.getDominant().then((res) => {
      console.log(res.data);
      this.title = res.data.name;
      document.title = this.title // change the page title
    }).catch((err) => ErrorResolver.checkErrors(err, err))
  },

  created() {
    if(!window.DATA){
      window.location.href = 'not_found'
      return;
    }
    this.loadUser();
    this.loadData();
  }
}
</script>


<style lang="scss">


.display {
  cursor: pointer !important;

  :hover {
    border: 2px solid #AE8B31 !important;
  }

  :active {
    border: 2px solid #AE8B31 !important;
  }
}

body::-webkit-scrollbar {
  width: 1em;
}


body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
</style>
