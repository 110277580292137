<template>
  <v-select :outlined="outlined" :rounded="rounded" v-bind="$attrs" :value="value" @input="$emit('input', $event)">
    <slot />
  </v-select>
</template>

<script>
export default {
  name: "XSelect",
  props: {
    value: [Object, Number, String],
    outlined: {
      default: true
    },
    rounded: {
      default: true
    }
  }
}
</script>

<style scoped>

</style>
