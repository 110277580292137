export class AppStorage {
    constructor() {
        this.storage = window.sessionStorage
        this.tokenName = "ctoken"
        this.refreshTokenName = "refresh"
    }

    getAccessToken(){
        return this.storage.getItem(this.tokenName)
    }

    setAccessToken(value){
        this.storage.setItem(this.tokenName, value)
    }

    getRefreshToken(){
        return this.storage.getItem(this.refreshTokenName)
    }

    setRefreshToken(value){
        this.storage.setItem(this.refreshTokenName, value)
    }

    resetAccessToken(){
        this.storage.removeItem(this.tokenName)
    }

    resetRefreshToken(){
        this.storage.removeItem(this.refreshTokenName)
    }
}
