class FingerprintSdk {
  constructor(connectFunc, disconnectFunc, acquiredFunc, qualityFunc, errorFunc) {
    this.sdk = new Fingerprint.WebApi;
    this.connectFunc = connectFunc
    this.disconnectFunc = disconnectFunc
    this.acquiredFunc = acquiredFunc
    this.qualityFunc = qualityFunc
    this.errorFunc = errorFunc
    // events
    this.sdk.onDeviceConnected = (e) => {
      this.connectFunc.call(this, e)
    }
    this.sdk.onDeviceDisconnected = (e) =>  {
      this.disconnectFunc.call(this, e)
    }
    this.sdk.onCommunicationFailed = (e) =>  {
      this.errorFunc.call(this, e)
    }
    this.sdk.onSamplesAcquired = (e) => {
      this.acquiredFunc.call(this, e)
    }
    this.sdk.onQualityReported = (e) => {
      this.qualityFunc.call(this, e)
    }
  }

  getDeviceList = function () {
    return this.sdk.enumerateDevices();
  };

  convertSample = (sample) => {
    return this.convertSampleV2(sample)
  }

  convertSampleV2 = (sample) => {
    return Fingerprint.b64UrlTo64(sample)
  }

  convertSampleV1 = (sample) => {
    return sample.replace(/-/g, '+').replace(/_/g, '/')
  }

  /**
   * @description Starts acquisition
   * @returns {Promise}
   */
  startCapture() {
    return this.sdk.startAcquisition(Fingerprint.SampleFormat.PngImage)
  };

  stopCapture() {
    return this.sdk.stopAcquisition()
  };

  storeCapture(key, data){
    let bioDataString = localStorage.getItem("biodata")
    let bioData = {}
    if(bioDataString){
      bioData = JSON.parse(bioDataString)
    }
    bioData[key] = data
    // store back
    localStorage.setItem("biodata", JSON.stringify(bioData))
    //   size
    localStorage.setItem("size", String(Object.keys(bioData).length))
  }

  captureCount(){
    return localStorage.getItem("size") || 0
  }

  getAllCaptures(){
    let bioDataString = localStorage.getItem("biodata")
    let bioData = {}
    if(bioDataString){
      bioData = JSON.parse(bioDataString)
    }
    return bioData;
  }

  isOffline() {
    let mode = localStorage.getItem("captureMode")
    if(mode == null || mode === "1")
      return false;
    // offline
    return true
  }

  getCaptureMode() {
    let mode = localStorage.getItem("captureMode")
    if(mode == null)
      return "1";
    // offline
    return mode;
  }

  setCaptureMode(val){
    localStorage.setItem("captureMode", val)
  }
}


export default FingerprintSdk
