import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Schools API hook, for handling schools in the system (C) 2021-2023
 */

const Schools =  {
    /**
     * @description Gets all schools
     * @returns {Promise<any[]>}
     */
    all: async() => {
        return axios.get(Links.schools.all)
    },

    /**
     * @description creates a new school
     * @param {object} data an object containing the following proporties: name, shortName, dominant(true or false)
     * @returns {Promise<object>} the newly created object
     */
    create: async (data) => {
        return axios.post(Links.schools.create, data)
    },

    /**
     * @description updates an existing school
     * @param {string} id the id of the school to update
     * @param {object} data an object containing the following proporties: name, shortName, dominant(true or false)
     * @returns {Promise<object>} the newly created object
     */
    update: async (id, data) => {
        return axios.put(Links.schools.update + '/' + id, data)
    },
    

    /**
     * @description gets the dominant school
     * @returns {Promise<object>}
     */
    getDominant: async () => {
        return axios.get(Links.schools.getDominant)
    }
}

export default Schools