import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Hospital API hook, for handling Hospital (C) 2021-2023
 */

const Hospital = {

    /**
     * @description Create a new hospital
     * @param {object} data  the payload of the request. contains the following properties: name, code
     * @returns {Promise<object>} The created hospital
     */
    create: async (data) => {
        return axios.post(Links.hospital.create, data)
    },

    /**
     * @description Updates an hospital
     * @param {string} id The id of the hospital to update
     * @param {object} data  the payload of the request. contains the following properties: name, code
     * @returns {Promise<object>} The created hospital
     */
    update: async (id, data) => {
        return axios.put(Links.hospital.update + '/' + id, data)
    },

    /**
     * @description Deletes a hospital object
     * @param {string} id the id of the hospital object to delete
     * @returns {Promise<object>} empty data
     */
    delete: async (id) => {
        return axios.delete(Links.hospital.delete + '/' + id)
    },

    /**
     * @description Gets all hospital data
     * @returns {Promise<any[]>} hospitals
     */
    getAll: async () => {
        return axios.get(Links.hospital.all)
    }
}

export default Hospital; 