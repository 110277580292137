<template>
  <div>
    <v-row justify="space-between">
      <v-col sm="2" v-for="i in length" :key="i">
        <code-input
          :ref="'ci' + i"
          @input="(e) => onChange(i,e)"
          @keyup.delete="() => back(i-1)"
          v-model="values[i]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CodeInput from "login/lafia/components/CodeInput.vue";
export default {
  name: "BoxedInput",
  components: { CodeInput },
  props: {
    length: {
      default: 1,
      type: Number,
    },
  },
  emits: ["readComplete"],
  data: () => ({
    values: [],
  }),

  methods: {
    onChange(index, rec) {
      if(this.values.length > (index)){
        this.values[index] = rec
      }
      if(index < this.$props.length && !!rec){
        // shift focus
        Object.values(this.$refs)[index][0].$refs.data.focus()
      }
      // values read
      this.emitValues()
    },

    back(index) {
      this.emitValues()
    },

    emitValues(){
      const val = this.values.filter(e => !!e.replaceAll(' ', '')).reduce((a,b) => `${a}${b}`, '')
      this.$emit("readComplete", val);
      // if(this.values.filter(e => !!e.replaceAll(' ', '')).length === this.$props.length){
      //   const val = this.values.reduce((a,b) => `${a}${b}`, '')
      //   this.$emit("readComplete", val);
      // }
    }
  },

  watch: {
    length: function(val){
        if(val){
            this.values = []
            for (let i = 0; i < this.length; i++){
                this.values.push('')
            }
        }
    }
  }
};
</script>

<style>
</style>
