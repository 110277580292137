<template>
  <div class="pa-4 d-flex flex-column" style="height: 100%">
    <div :class="{'primary--text mb-6': true,}">
      <h1 style="color: #223345" v-if="!isMobile">Forgot Password</h1>
      <h2 style="color: #223345" class="text-center text-uppercase" v-else>Forgot Password</h2>
    </div>
    <v-alert color="drawerBg" class="white--text" v-if="isMobile">Follow instructions to change your password</v-alert>
    <div class="basic--text mb-8" v-else>Follow instructions to change your password</div>
    <div v-if="step === 0" :class="{'q pa-2 rounded qb': isMobile}">
      <div class="mt-5">
        <v-text-field dense outlined placeholder="User ID" v-model="reqObj.username"></v-text-field>
      </div>
      <div class="mt-6">
        <span style="color: #223345" class="ml-1">Remember password? </span><a href="" class="primary--text" @click.prevent="close">Click here</a>
      </div>
      <div class="my-6">
        <v-btn color="drawerBg" rounded elevation="0" type="primary" width="100%" class="white--text" :loading="loading" @click="sendOtp">Request OTP</v-btn>
      </div>
    </div>
    <div v-else-if="step === 1" :class="{'q pa-2 rounded qb': isMobile}">
      <div class="">

        <div class="font-weight-bold mb-2">Enter OTP</div>
        <v-text-field dense outlined placeholder="OTP" v-model="reqObj.password"></v-text-field>
      </div>
      <div class="mt-6">
        <span style="color: #223345" class="ml-1">Remember password? </span><a href="" class="primary--text" @click.prevent="close">Click here</a>
      </div>
      <div class="my-6">
        <v-btn color="drawerBg" rounded elevation="0" type="primary" width="100%" class="white--text my-2" :loading="loading" @click="verifyOtp">Verify</v-btn>
        <v-btn color="info" rounded elevation="0" type="info" width="100%" class="white--text my-2" :loading="loading" @click="sendOtp">Resend OTP</v-btn>
      </div>
    </div>
    <div v-else :class="{'q pa-2 rounded qb': isMobile}">
      <v-form ref="form" v-model="passwordValid">
        <div class="">
        <div class="font-weight-bold mb-2">Password*</div>
        <v-text-field dense outlined type="password" placeholder="Password" class="mb-0" v-model="passwordReq.newPassword" required :rules="passwordRules"></v-text-field>
        <div class="font-weight-bold mb-2 mt-4">Confirm Password*</div>
        <v-text-field dense outlined type="password" placeholder="Password" class="mb-0" v-model="passwordReq.confirmPassword"></v-text-field>
        <div class="error--text" style="font-size: 8pt" v-if="passwordMismatch">Password mismatch</div>
      </div>
      </v-form>
      <div class="mt-6">
        <span style="color: #223345" class="ml-1">Remember password? </span><a href="" class="primary--text" @click.prevent="close">Click here</a>
      </div>
      <div class="my-6">
        <v-btn
            color="drawerBg"
            rounded
            elevation="0"
            type="primary" width="100%" class="white--text"
            :disabled="!passwordValid || passwordReq.newPassword == null || passwordReq.confirmPassword === '' || passwordReq.newPassword.length < 8 || passwordMismatch"
            :loading="loading" @click="updatePasswordForgot">Update Password</v-btn>
      </div>
    </div>
    <v-spacer></v-spacer>
    <div class="basic--text mt-2 text-center">&copy; {{ new Date().getFullYear() }} {{ globalConfig.institutionName }}</div>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>
</template>

<script>
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import MessageBox from "@ui/components/MessageBox";
import ValidationRules from "@/shared/ValidationRules";

export default {
  name: "ForgotPassword",
  props: {globalConfig: Object},
  components: {MessageBox},
  data: () => ({
    step: 0,
    reqObj: {
      username: '',
      password: ''
    },

    passwordReq: {
      userid: '',
      newPassword: '',
      confirmPassword: ''
    },

    messageType: '',
    message: '',
    messageShow: false,
    loading: false,
    passwordValid: true,
    passwordRules: ValidationRules.passwordRules,
  }),

  methods: {
    close(){
      this.$emit("close")
    },

    sendOtp(){
      this.loading = true
      this.reqObj.password = 'a'
      Api.auth.sendOtp(this.reqObj)
      .then(res => {
        if(res.data.success){
          this.message = "OTP Sent"
          this.messageType = 'success'
          this.messageShow = true
          if(this.step === 0)
            this.step++
        } else{
          this.message = "Failed to send OTP"
          this.messageType = 'error'
          this.messageShow = true
        }
      }).catch(err => ErrorResolver.checkErrors(this, err))
      .finally(() => {
        this.loading = false
        this.reqObj.password = ''
      })
    },

    verifyOtp(){
      this.loading = true
      Api.auth.verifyOtp(this.reqObj)
          .then(res => {
            if(res.data.success){
              this.message = "OTP verified"
              this.messageType = 'success'
              this.messageShow = true
              this.step++
            } else{
              this.message = "Invalid OTP"
              this.messageType = 'error'
              this.messageShow = true
            }
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },

    updatePasswordForgot(){
      this.loading = true
      this.passwordReq.userId = this.reqObj.username
      Api.auth.updatePasswordForgot(this.passwordReq)
          .then(() => {
            this.message = "Password updated"
            this.messageType = 'success'
            this.messageShow = true
            this.close()
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    }

  },

  computed: {
    passwordMismatch: function(){
      return this.passwordReq.confirmPassword !== '' && this.passwordReq.newPassword !== this.passwordReq.confirmPassword
    },

    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created(){
    this.step = 0
  }
}
</script>

<style scoped>
.q{
  background-color: white;
}

.qb{
  border: #d7e2e8 1px solid;
}


</style>
