// an entry point for vuex
import Vue from 'vue'
import Vuex from 'vuex'
import GeneralStore from './modules/generalStore'

// Load Vuex
Vue.use(Vuex)

// create Store(s)
export default new Vuex.Store({
    modules: {
        GeneralStore,
    }
});