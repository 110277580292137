<template>
  <!-- App.vue -->

  <v-app style="background-color: #F5F5F5" v-if="getUser != null">
    <!--  navigation  -->
    <sidebar
      :open="mini"
      :items="{managements: linksFiltered, dashboard: 'dashboard'}"
      @close="mini"
    ></sidebar>

    <v-app-bar tile app dense hide-on-scroll color="white" v-if="userLoaded">
      <!-- -->
      <v-icon @click.stop="mini = !mini" color="black">mdi-menu-open</v-icon>
      <v-spacer></v-spacer>

      <h4 class="" v-if="$vuetify.breakpoint.mdAndUp">{{ (new Date()).toDateString() }}</h4>
      <div class="nav-actions">
        <v-icon color="error" style="cursor: pointer" @click="showLogout = !showLogout">mdi-power</v-icon>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main id="main-app" v-if="getUser != null">

      <!-- Provides the application the proper gutter -->
      <v-container fluid style="height: 100%">
        <beep-import></beep-import>
        <!-- If using vue-router -->
        <router-view></router-view>
        <confirmation-box message="Continue to logout?"
                          :show="showLogout" v-on:positive="logUserOut" v-on:negative="showLogout = false">
        </confirmation-box>
      </v-container>
    </v-main>
  </v-app>

  <v-app style="background-color: #F5F5F5" v-else>
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid style="height: 100%">
        <div class="d-flex flex-column" style="height: 100%">
          <div class="mx-auto my-auto">
            <loading-dialog :show="getUser == null"></loading-dialog>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import Sidebar from "@ui/components/Sidebar.vue";
import ErrorResolver from "@/shared/ErrorResolver";
import Api from "@/main/api";
import LoginUtil from "@/login/LoginUtil";
import Defaults from "@/shared/Defaults";
import Roles from "@/shared/Roles";
import StudentStatus from "@/shared/StudentStatus";
import NavigationLinks from "@/shared/NavigationLinks";
import LoadingDialog from "@ui/components/LoadingDialog.vue";
import ConfirmationBox from "@/components/ConfirmationBox.vue";
import BeepImport from "@ui/components/BeepImport.vue";

export default {
  name: 'App',
  components: {
    BeepImport,
    ConfirmationBox,
    LoadingDialog,
    Sidebar,
  },

  data: () => ({
    //
    mini: false,
    viewOption: 1, // to put in vuex state
    programmeTypes: [],
    image: '',
    showLogout: false,
    navLinks: NavigationLinks.StudentLinks,
    links: ['Logout', 'Settings'],
    managements: [
      { title: 'Dashboard', icon: 'mdi-speedometer', url: 'dashboard', cleared: false, mustBePermanent: true },
      { title: 'My Application', icon: 'mdi-application', url: 'application', cleared: false, mustBePermanent: true },
      { title: 'Change of course', icon: 'mdi-ev-plug-chademo', url: 'change-program', cleared: false, mustBePermanent: true },
      { title: 'Information correction', icon: 'mdi-database-edit-outline', url: 'data-correction', cleared: false, mustBePermanent: true },
    ]
  }),

  methods: {
    ...mapActions(['fetchCurrentUser', 'fetchProgrammeTypes', 'logout']),
    setProgrammeType(val) {
      let v = this;
      Api.session.current(val.id).then(res => {
        v.$store.commit('setCurrentSession', res.data);
        this.$store.commit('setCurrentProgrammeType', val)
      }).catch(err => {
        ErrorResolver.checkErrors(this, err);
      })
    },

    logUserOut() {
      this.logout()
      // this.setCurrentUser({user: null})
      window.location.href = '/login'
    },

    checkAndLoadData() {
      if (LoginUtil.checkAuth()) {
        // not logged in
        this.loading = true;
        this.getInitialData()
            .then((res) => {
              let user = res.data.userData.user;
              if (!Roles.contains(user.roles, Roles.Applicant)) {
                LoginUtil.resetAccessToken();
                LoginUtil.resetRefreshToken();
                window.location.href = "/login";
                return;
              }

              this.$store.commit("setCurrentUser", res.data.userData);
              this.$store.commit("setInformation", res.data.information);
              this.$store.commit("setUTMEDetails", res.data.utmeDetails);
              this.$store.commit("setSSCEDetails", res.data.ssceDetails);
              this.$store.commit("setNYSCDetails", res.data.nyscDetails);
              this.$store.commit("setQualificationDetails", res.data.qualificationCertificate);
              this.$store.commit("setApplicationFee", res.data.applicationFee);
              this.$store.commit("setAdmissionFee", res.data.admissionFee);
              this.$store.commit("setScreeningFee", res.data.screeningFee);
              this.$store.commit("setScratchCards", res.data.scratchCards);

              // if user has not started application already
              if (user.firstName != null) {
                // take to application

                if(this.$route.path !== "/dashboard"){
                  this.$router.push('dashboard');
                }
              }
            })
            .catch((err) => ErrorResolver.checkErrors(this, err))
            .finally(() => (this.loading = false));
      }
    },

    changeTitle() {
      Api.schools.getDominant().then((res) => {
        document.title = res.data.name // change the page title
      }).catch((err) => ErrorResolver.checkErrors(err, err))
    },

    exitSuper(){
      this.loading = true
      Api.auth.revertSwitchUser({
        user: this.getUser.userId
      })
          .then(res => {
            window.close() // close tab
            // store token
            // LoginUtil.setAccessToken(res.data.jwt)
            // LoginUtil.setRefreshToken(res.data.refreshToken);
            // // change location
            // window.location.href = "/main"
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },

    ...mapActions(["getInitialData"]),

  },

  watch: {
    getUser: function (newVal) {
      if (newVal != null) {
        this.image = Defaults.api + '/profile-picture/fetch?user=' + this.getUser.userId;
      }
    },

    showLogout: function(val){
      if(val){
        document.getElementById('audiobeep').play();
      }
    }
  },

  computed: {
    ...mapGetters(['getProgrammeTypes', 'getCurrentProgrammeType', 'getUser', 'getSuper']),
    isLarge: function () {
      return this.$vuetify.breakpoint.lgAndUp
    },

    userLoaded: function () {
      return this.getUser != null;
    },

    linksFiltered: function(){
      return this.managements.filter(e => e.url !== 'change-program' || (e.url === 'change-program' && this.canChangeProgramme));
    },

    canChangeProgramme: function () {
      return this.getUser != null && (this.getUser.admission.applicationType.changeProgrammesStatus.includes(this.getUser.studentStatus)
          || this.getUser.studentStatus === StudentStatus.PROGRAMME_CHANGE.text) && !this.getUser.migrated
    },
  },

  mounted() {
    Api.schools.getDominant().then((res) => {
      this.title = res.data.name;
      document.title = this.title // change the page title
    }).catch((err) => ErrorResolver.checkErrors(err, err))
  },

  created() {
    Api.global
        .fetch()
        .then((res) => {
          this.global = res.data;
        })
        .catch((err) => ErrorResolver.checkErrors(this, err));

    this.changeTitle();
    this.checkAndLoadData();
  }

};
</script>
<style scoped lang="scss">
@import "@/resources/styles/main.scss";

.nav-actions > * {
  margin-left: 16px;
}

.btn {
  text-transform: none;
}

</style>
