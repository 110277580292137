const { default: axios } = require("axios")
const { default: Links } = require("../constants/Links")



/**
 * This is the DataCorrection API hook for handling data correction (C) 2021-2023
 *  
 * */

const DataCorrection = {
    /**
     * @description Apply for data correction
     * @param {string} data an object containing the following: userId(User id of the applicant), type (type of data correction. Integer),
     * currentData(the data to be changed) and newData (The data to replace the old one with)
     * @returns {Promise<object>}
     */
    apply: async (data) => {
        return axios.post(Links.dataCorrections.apply, data)
    },

    /**
     * @description Fetches pending application for an applicant
     * @param {object} user user id of the applicant
     * @returns {Promise<object>}
     */
    fetchPending: async (user) => {
        return axios.get(Links.dataCorrections.fetchPending + '?user=' + user)
    },

    /**
     * Fetch all record
     * @description Fetches history of all solved applications for an applicant
     * @param {object} user user id of the applicant
     * @returns {Promise<object>} user history of application changed
     */
     fetchHistory: async (user) => {
        return axios.get(Links.dataCorrections.fetchHistory + '?user=' + user)
     },

    /**
     * @description Fetch all record
     * @param {string} programmeType the programme type to fetch for
     * @param {string} session the session to fetch for
     * @returns {Promise<object>}
     */
    fetchAll: async (programmeType, session, page, size) => {
        const params = '?programme_type=' + programmeType + '&session=' + session + "&page=" + page + '&size=' + size;
        return axios.get(Links.dataCorrections.fetchAll + params)
    },

    /**
     * @description Fetch all that have been cleared
     * @param { string } programmeType the programme type to fetch for
     * @param {string} session the session to fetch for
     * @param {string} page page number
     * @param {string} size size of content
     * @returns {Promise<object>}
     */
     fetchAllCleared: async (programmeType, session, page, size) => {
        const params = '?programme_type=' + programmeType + '&session=' + session + "&page=" + page + '&size=' + size;
        return axios.get(Links.dataCorrections.fetchCleared + params)
    },

    /**
     * @description Toggles the clear state of the resource
     * @param {string} id ID of the data correction resource to clear
     * @returns {Promise<boolean>a}
     */
    toggleClear: async (id) => {
        return axios.put(Links.dataCorrections.toggleClear + '/' + id)
    },

    /**
     * @description Mark the resource as solved after correction of the applicant data
     * @param {string} id ID of the resource to mark as solved
     * @returns {Promise<boolean>} approve || dis-approve
     */
    markSolved: async (id) => {
        return axios.put(Links.dataCorrections.markSolved + '/' + id)
    }
}; 

export default DataCorrection;