import {AppStorage} from "@/login/appstorage";
import TabUtil from "@/shared/tabutil";
import {getBrowserInfo} from "@/shared/BrowserUtil";

let storage = new AppStorage()

export default {
    getCookie: function(cname) {
        return storage.getAccessToken()
    },

    setCookie: function(cname, cvalue, exhours) {
        storage.setAccessToken(cvalue)
    },

    setAccessToken: function(val){
        storage.setAccessToken(val)
    },

    getAccessToken: function(){
        return storage.getAccessToken()
    },

    resetAccessToken: function(){
        storage.resetAccessToken()
    },

    getRefreshToken: function(){
        storage.getRefreshToken()
    },

    setRefreshToken: function(val){
        storage.setRefreshToken(val)
    },

    resetRefreshToken: function(){
        storage.resetRefreshToken()
    },

    checkAuth: function () {
        // check for request data passing
        if(window.location.href.includes("?data")) {
            let data = TabUtil.extractData(window.location.search)
            // get browser info
            let info = `${getBrowserInfo().userAgent}`
            if(data) {
                // if copied from another browser
                if(info !== data.info)
                    return false;

                this.setAccessToken(data.key)
                this.setRefreshToken(data.key2)
                window.location.href = window.location.href.substring(0, window.location.href.indexOf("?data"))
                return true
            }
        }

        // check if user is logged in
        let loggedIn = this.getAccessToken() || this.getRefreshToken()
        // if logged in, return success promise
        return !!loggedIn;
    }
}
