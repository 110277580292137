import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Split Account API hook, for handling split account in the system (C) 2021-2023
 * The split payment object has the following properties; bankName, beneficiaryName, beneficiaryAccount, bankCode, amount
 *  @type {{all: (function(): Promise<AxiosResponse<any>>), create: (function(*): Promise<AxiosResponse<any>>), update: (function(*, *): Promise<AxiosResponse<any>>), delete: (function(*): Promise<AxiosResponse<any>>)}}
 * */



const SplitAccounts = {
    /**
     * returns all split accounts
     * @returns {Promise<AxiosResponse<*>>}
     */
    all: async () => {
        return axios.get(Links.splitAccounts.all)
    },

    /**
     * Creates a split account with the properties listed on the docs of the object containing this function
     * @param {object} data the object containing the data of the account to be created
     * @returns {Promise<AxiosResponse<*>>}
     */
    create: async (data) => {
        return axios.post(Links.splitAccounts.create, data)
    },

    /**
     * Updates a split account
     * @param {string} id the database id of the account to be created
     * @param {object} data the new data to update the account with
     * @returns {Promise<AxiosResponse<*>>}
     */
    update: async (id, data) => {
        return axios.put(Links.splitAccounts.update + '/' + id, data)
    },

    /**
     * Deletes a split account
     * @param {string} id the database id of the account to be deleted
     * @returns {Promise<AxiosResponse<*>>}
     */
    delete: async (id) => {
        return axios.delete(Links.splitAccounts.delete + '/' + id)
    },
}

export default SplitAccounts