import axios from "axios";
import Links from "../constants/Links";

/**
 * Application API hook 
 * 
 */
const Application = {
  /**
   * Fetch general applications on the system based on the selected session 
   * @param session - application for the selected session
  */
  applicationStatistics: (session) => {
    return axios.get(Links.application.statistics + "?session=" + session)
  }
}; 

export default Application;