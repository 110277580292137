<template>
  <div class="pa-0 px-md-2 mb-md-4 d-flex flex-column" style="height: 100%">

    <div v-if="step === 0">
      <div :class="{'primary--text mb-6': !isMobile }">
        <h1 style="color: #585858; font-weight: bold; font-size: 2rem"  v-if="!isMobile">Reset your Password </h1>
        <h2 style="color: #585858; font-size: 1.5rem;" class="text-center text-uppercase" v-else>Reset Password</h2>
      </div>

      <div class="text-center mt-2" style="color: #585858; font-size:0.8rem" v-if="isMobile">
        The verification will be sent to your mailbox. Please check it.
      </div>
      <div class="mb-4" style="color: #585858;" v-else>
        The verification will be sent to your mailbox. Please check it.
      </div>
    </div>

    <div v-else-if="step === 1">
      <div :class="{'primary--text mb-6': true}" >
        <h1 style="color: #585858; font-weight: bold;"  v-if="!isMobile">Enter OTP </h1>
        <h2 style="color: #585858" class="text-center text-uppercase" v-else>Enter OTP </h2>
      </div>

      <div style="color: #585858;" class="" v-if="isMobile">
        Enter the OTP sent to your mailbox
      </div>
      <div class="mb-4" style="color: #585858;" v-else>
        Enter the OTP sent to your mailbox
      </div>
    </div>

    <div v-else-if="step === 2" class="font-weight-bold" style="font-weight: bolder !important;">
      <div :class="{'primary--text mb-6': true }">
        <h1 style="color: #585858;"  v-if="!isMobile">Create new password</h1>
        <h2 style="color: #585858" class="text-center text-uppercase font-weight-bold"  v-else>Create new password</h2>
      </div>

      <v-alert style="color: #585858;" class="" v-if="isMobile">
        Provide a secure password
      </v-alert>
    </div>

    <div v-else>
      <h4 class="text-center font-weight-bold text--success" style="color: #029241">Password Reset Successful</h4>
    </div>


    <div v-if="step === 0" :class="{'q rounded qb': isMobile}" :style="{padding: isMobile ? '1.2rem' : 'auto'}">
      <div class="mt-5">
        <div class="font-weight-black font-weight-bold" style="color: black !important; margin-bottom: .4em;">Email address/User ID</div>
        <v-text-field
          outlined
          solo
          placeholder="User ID/Email"
          v-model="reqObj.username"
          @keyup.enter="sendOtp"
        />
      </div>

      <div class="my-4">
        <v-btn depressed large color="primary" width="100%" class="white--text" :loading="resendLoading" @click="sendOtp">Reset Password</v-btn>
      </div>


      <div class="mt-md-8 text-center">
        <span :style="{color: $vuetify.theme.currentTheme.primary, fontWeight: '700'}" >
          <a @click="close">Login</a>
        </span>
      </div>

    </div>

    <!-- OTP OPERATION -->
    <div v-else-if="step === 1" :class="{'q pa-2 rounded qb': isMobile}">
      <v-otp-input
        length="6"
        v-model="reqObj.password"
        :disabled="loading || resendLoading"
      />

      <div class="d-flex flex-column align-center mt-4" style="width: 100%;">
        <v-btn
          class="my-4"
          depressed
          width="100%"
          color="primary"
          type="primary"
          :loading="loading"
          large
          @click="verifyOtp">
          Verify
        </v-btn>

        <v-btn
          depressed
          width="100%"
          :loading="resendLoading"
          color="secondary"
          @click="sendOtp"
          text
          small
          class=""
        >
          Resend
        </v-btn>
      </div>

      <div class="mt-2 text-center">
          <span :style="{color: $vuetify.theme.currentTheme.primary, fontWeight: '700'}" >
          <a @click="close">Login</a>
        </span>
      </div>
    </div>

    <!-- confirm password operation -->
    <div v-else-if="step === 2" :class="{'q pa-2 rounded qb': isMobile}">
      <v-form ref="form" v-model="passwordValid">
        <div class="">
          <div class="font-weight-black font-weight-bold" style="color: black !important; margin-bottom: .4em;">New password</div>
        <v-text-field  outlined solo type="password" placeholder="Password" class="mb-0" v-model="passwordReq.newPassword" required :rules="passwordRules"></v-text-field>
          <div class="font-weight-black font-weight-bold" style="color: black !important; margin-bottom: .4em;">Confirm password</div>
        <v-text-field outlined solo type="password" placeholder="Confirmed Password" class="mb-0" v-model="passwordReq.confirmPassword"></v-text-field>
        <div class="error--text" style="font-size: 8pt" v-if="passwordMismatch">Password mismatch</div>
      </div>
      </v-form>

      <div class="my-6">
        <v-btn
            color="drawerBg"
            large
            elevation="0"
            depressed
            type="primary"
            width="100%"
            class="white--text"
            :disabled="!passwordValid || passwordReq.newPassword == null || passwordReq.confirmPassword === '' || passwordReq.newPassword.length < 8 || passwordMismatch"
            :loading="loading" @click="updatePasswordForgot">Update Password</v-btn>
      </div>
    </div>

    <div v-else>
      <div class="mt-12">
        <v-btn
          color="drawerBg"
          large
          elevation="0"
          type="primary"
          width="100%"
          class="white--text"
          @click="close">LOGIN</v-btn>
      </div>
    </div>

    <message-box
        :show="messageShow"
        :message="message"
        :type="messageType"
        v-on:close-message="messageShow = !messageShow">
    </message-box>
  </div>
</template>


<script>
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import MessageBox from "@ui/components/MessageBox.vue";
import ValidationRules from "@/shared/ValidationRules";
import Loading from "ui/components/Loader";

export default {
  name: 'ForgotPassword',
  props: ['logo', 'showForgotPassword'],
  components: {
    MessageBox,
    Loading,
  },
  emits: ['close'],
  data: () => ({
    step: 0,
    reqObj: {
      username: '',
      password: ''
    },

    passwordReq: {
      userid: '',
      newPassword: '',
      confirmPassword: ''
    },

    messageType: '',
    message: '',
    messageShow: false,
    loading: false,
    resendLoading: false,
    passwordValid: true,
    passwordRules: ValidationRules.passwordRules,
  }),

  methods: {
    close(){
      this.$emit("close");
    },

    sendOtp(){
      if(!this.reqObj.username)
        return

      this.resendLoading = true
      this.reqObj.password = ''
      Api.auth.sendOtp(this.reqObj)
      .then(res => {
        if(res.data.success){
          this.message = "OTP Sent"
          this.messageType = 'success'
          this.messageShow = true
          if(this.step === 0)
            this.step++
        } else{
          this.message = "Failed to send OTP"
          this.messageType = 'error'
          this.messageShow = true
        }
      }).catch(err => ErrorResolver.checkErrors(this, err))
      .finally(() => {
        this.resendLoading = false
        this.reqObj.password = ''
      })
    },

    verifyOtp(){
      if(!this.reqObj.password)
        return

      this.loading = true
      Api.auth.verifyOtp(this.reqObj)
          .then(res => {
            if(res.data.success){
              this.message = "OTP verified"
              this.messageType = 'success'
              this.messageShow = true
              this.step++
            } else{
              this.message = "Invalid OTP"
              this.messageType = 'error'
              this.messageShow = true
            }
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },

    updatePasswordForgot(){
      this.loading = true
      this.passwordReq.userId = this.reqObj.username
      Api.auth.updatePasswordForgot(this.passwordReq)
          .then(() => {
            this.message = "Password updated"
            this.messageType = 'success'
            this.messageShow = true
            this.step = 3
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    }

  },

  computed: {
    passwordMismatch: function(){
      return this.passwordReq.confirmPassword !== '' && this.passwordReq.newPassword !== this.passwordReq.confirmPassword
    },

    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created(){
    this.step = 0;
  }
}
</script>
