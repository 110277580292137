import axios from "axios";
import Links from "shared/constants/Links";


/**
 * This is the External API hook, for handling external
 * (C) 2021-2023
 *  
 * */


const External = {
    /**
     * To create external details
     * @param {object} data 
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.external.create, data)
    }, 

    /**
     * To update external details
     * @param {string} id 
     * @param {object} data 
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.external.update + '/' + id, data)
    }, 

    /**
     * To delete external details
     * @param {string} id external id
     * @returns {Promise<object>}
     */
    delete: async (id) => {
        return axios.delete(Links.external.delete + '/' + id)
    }, 

    /**
     * fetch all external details 
     * @param {string} page 
     * @param {string} size 
     * @returns {Promise<any[]>}
     */
    all: async (page, size) => {
        return axios.get(Links.external.all + '?page=' + page + '&size=' + size)
    }
}; 


export default External; 