<template>
  <v-row justify="center">
    <loading :active.sync="show"
             :can-cancel="false"
             loader="dots"
             :color="$vuetify.theme.currentTheme.primary"
             background-color="#fff"
             :is-full-page="true"/>
  </v-row>
</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "LoadingDialog",
  components: {
    Loading
  },
  props: {
    show: Boolean,
    message: String,
  }
}
</script>

<style scoped>
.transparent {
  background-color: transparent;
}

</style>
