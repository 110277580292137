import axios from "axios";
import Links from "@/shared/constants/Links";

/**
 * This is the Screening Reason API hook, for handling screening reason in the system (C) 2021-2023
 */

const ScreeningReason = {
    /**
     * create a screening reason
     * @param {object} data info to be created
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.reason.create, data)
    },

    /**
     * update screening reason
     * @param {string} id id of the screening reason
     * @param {object} data info to be updated
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.reason.update + '/' + id, data)
    },

    /**
     * clear screening reasons
     * @param {string} id id of the screening reason
     * @returns {Promise<object>}
     */
    delete: async (id) => {
        return axios.delete(Links.reason.delete + '/' + id)
    },

    /**
     * fetch all screening reasons
     * @returns {Promise<any[]>}
     */
    fetchAll: async () => {
        return axios.get(Links.reason.fetchAll)
    }
}

export default ScreeningReason