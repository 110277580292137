<template>
  <audio id="audiobeep" src="/other/beep.mp3" preload="auto"></audio>
</template>

<script>
export default {
  name: "BeepImport"
}
</script>

<style scoped>

</style>
