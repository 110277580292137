import axios from "axios";
import Links from "../constants/Links";



/**
 *  This is the ProgrammeType API hook, for handling programme type (C) 2021-2023
 */
const ProgrammeType = {
    /**
     * to create a programmetype
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.programmeTypes.create, data)
    },

    /**
     * to update programmetype 
     * @param {string} id id of the programmetype
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.programmeTypes.update + '/' + id, data)
    },

    /**
     * fetch all programmetype in the system
     * @returns {Promise<any[]>}
     */
    all: async () => {
        return axios.get(Links.programmeTypes.all)
    },

    /**
     * @description Updates payment settings for a programme type
     * @param {string} id the ID of the programme type
     * @param {object} data and object containing: feePaymentMode(1 for Session and 2 for semester), 
     * partPayment(true or false), 
     * firstPaymentPercentage (number between 0.0 - 100.0), serviceCode(string)
     * @returns {Promise<object>} the updated programme type
     */
    updatePaymentDetails: async (id, data) => {
        return axios.put(Links.programmeTypes.updatePaymentDetails + '/' + id, data)
    },

    /**
     * @description Returns programme types for the currently logged in user
     */
    allForCurrentUser: async () => {
        return axios.get(Links.programmeTypes.forCurrenUser)
    }
}

export default ProgrammeType