import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);


const routes = [
    { path: '/application', component: () => import("../pages/lafia/Application.vue")},
    { path: '/dashboard', component: () => import("../pages/lafia/Dashboard.vue")},
    { path: '/change-program', component: () => import("../pages/lafia/Program.vue")},
    { path: '/data-correction', component: () => import("../pages/lafia/Data-Correction.vue") },
    { path: '/verify', component: () => import("../pages/lafia/Verification.vue") },
];



export default new VueRouter({
    routes
})
