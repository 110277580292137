import axios from "axios";
import Links from "@/shared/constants/Links";


/**
 * This is the Scratch Card API hook, for handling scratch card in the system (C) 2021-2023
 */
const ScratchCard = {
    /**
     * fetch configuration base on the entered org name
     * @param {string} organization 
     * @returns {Promise<any[]>}
     */
    getConfig: async (organization) => {
        return axios.get(Links.scratchCards.getConfig + '?organization=' + organization)
    },

    /**
     * update a configuration details of an org
     * @param {string} id id of the org
     * @param {object} data info of the org
     * @returns {Promise<object}
     */
    updateConfig: async (id, data) => {
        return axios.put(Links.scratchCards.updateConfig + '/' + id, data)
    },

    /**
     * start transaction payment for scratch payment
     * @param {object} data info of the org
     * @returns {Promise<object>}
     */
    initializeTransaction: async (data) => {
        return axios.post(Links.scratchCards.initializeTransaction, data)
    },

    /**
     * verify the purchase of scratch card
     * @param {string} reference 
     * @returns {Promise<object>}
     */
    verifyTransaction: async (reference) => {
        return axios.put(Links.scratchCards.verifyTransaction + '/' + reference)
    },

    /**
     * upload bulk scratch card
     * @param {object} data bulk sratch card to uploaded
     * @returns {Promise<Blob>}
     */
    upload: async (data) => {
        return axios.post(Links.scratchCards.upload, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * fetch all scratch cards of an org
     * @param {string} org name of the org
     * @param {string} type the type of scratch card WAEC or NECO
     * @param {string} page e.g 0
     * @param {string} size e.g 10
     * @returns {Promise<any[]>}
     */
    fetch: async (org, type, page, size) => {
        return axios.get(Links.scratchCards.fetch + '?org=' + org + '&type=' + type + '&page=' + page + '&size=' + size)
    },

    /**
     * update a scratch card details
     * @param {object} data 
     * @returns {Promise<object>}
     */
    updateNew: async (data) => {
        return axios.post(Links.scratchCards.updateNew, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    }
}

export default ScratchCard