<template>
  <div class="mb-4">

    <v-row align-content="center" class="mt-2">
      <v-col cols="12" md="12">
        <div>
          <h1 v-if="!isMobile" class="mb-0 mt-0" style="color: #585858; font-size: 2rem; font-weight: bold; line-height: 0.4rem;">
            Application
          </h1>
          <h1 v-else  class="text-center" style="font-size: 1.5rem; color: #585858;">Application</h1>

          <div>
            <p v-if="!isMobile" class="pt-5" style="font-size: 0.8rem; color: #585858">Please provide accurate information</p>
            <p v-else  class="text-center" style="font-size: 0.8rem; color: #585858">Please provide accurate information</p>
          </div>
        </div>


        <div v-show="registering">
          <v-select
            placeholder="Select application"
            item-text="id"
            required
            outlined
            solo
            class="mt-0"
            :rules="textRules"
            :style="{ width: isMobile ? '100%' : '100%'}"
            :items="admissions"
            v-model="admission"
            return-object
        >
          <template v-slot:item="{ item }">
            {{ item.applicationType.name + " " + item.session.name }}
          </template>

          <template v-slot:selection="{ item }">
            {{ item.applicationType.name + " " + item.session.name }}
          </template>
        </v-select>

          <v-form v-model="dataValid" ref="form">
        <div v-show="admission != null && admission.applicationType.autoLoadUtme === true">
          <v-text-field
            placeholder="UTME or User Id"
            required
            outlined
            solo
            v-model="jambRegNumber"
            :rules="textRules"
            :style="{ width: isMobile ? '100%' : '100%'}"
          />
        </div>

        <div v-show="admission != null && admission.applicationType.modeOfEntryEnabled === true">
          <v-select
            class="mt-3"
            :items="modeOfEntries"
            item-text="title"
            item-value="id"
            placeholder="Mode of entry"
            v-model="obj.modeOfEntryId"
            :style="{ width: isMobile ? '100%' : '100%'}"
            required
            outlined
            solo
            :rules="textRules">
          </v-select>
        </div>
        <v-text-field
          required
          :rules="emailRules"
          v-model="obj.emailAddress"
          placeholder="Email"
          :style="{ width: isMobile ? '100%' : '100%'}"
          outlined
          solo
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          placeholder="Password"
          v-model="obj.password"
          required
          :rules="passwordRules"
          :style="{ width: isMobile ? '100%' : '100%'}"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          outlined
          solo
          @click:append="showPassword = !showPassword"
          >
        </v-text-field>
        <v-text-field
          :style="{ width: isMobile ? '100%' : '100%'}"
          :type="showPasswordC ? 'text' : 'password'"
          placeholder="Confirm Password"
          v-model="obj.confirmPassword"
          required
          :rules="passwordRules"
          :append-icon="showPasswordC ? 'mdi-eye-off' : 'mdi-eye'"
          outlined
          solo
          @click:append="showPasswordC = !showPasswordC"
          ></v-text-field>


        <div v-if="registering">
          <v-btn
            @click="submitData"
            :loading="loading"
            color="primary"
            class="mt-4"
            width="100%"
            height="2.5rem">
            Register
          </v-btn>
        </div>
      </v-form>


<!--          <div class="d-flex flex-column align-left pt-md-8 pt-8 pb-md-7 pb-10">-->
<!--          <div class="text-left pt-2" style="font-size: 0.8rem;">-->
<!--            Having an account already?-->
<!--            <a class="ml-0" style="font-weight: bold; letter-spacing: 0; text-shadow: 1px 0 #c52a5f; text-decoration: none;" href="/login" >Login</a>-->
<!--          </div>-->
<!--        </div>-->

        </div>
      </v-col>
    </v-row>

    <loading-dialog :show="loading"></loading-dialog>
    <message-box
      :show="messageShow"
      :message="message"
      :type="messageType"
      v-on:close-message="messageShow = !messageShow"
    ></message-box>
  </div>
</template>

<script>
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import ValidationRules from "@/shared/ValidationRules";
import MessageBox from "@ui/components/MessageBox";
import LoadingDialog from "ui/components/LoadingDialog.vue";

export default {
  name: 'Application',
  components: {
    MessageBox,
    LoadingDialog
  },
  data: () => ({
    showPassword: false,
    showPasswordC: false,
    registering: true,
    loading: false,
    dataValid: true,
    obj: {},
    admissions: [],
    modeOfEntries: [],
    messageType: "",
    message: "",
    messageShow: false,
    jambRegNumber: null,
    admission: null,
    textRules: ValidationRules.textRules,
    emailRules: ValidationRules.emailRules,
    passwordRules: ValidationRules.passwordRules,
  }),

  methods: {
    getAdmissions() {
      this.loading = true;
      Api.admission
        .getActiveAdmissions()
        .then((res) => {
          this.admissions = res.data;
        })
        .catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => (this.loading = false));
    },


    loadModeOfEntries() {
      if (this.admission != null) {
        this.modeOfEntries = [];
        Api.system
          .modeOfEntries(this.admission.session.programmeType.id)
          .then((res) => (this.modeOfEntries = res.data))
          .catch((err) => ErrorResolver.checkErrors(this, err));
      }

    },

    register() {
      this.loading = true;
      this.obj.admissionId = this.admission.id;
      this.obj.jambRegNumber = this.jambRegNumber;
      Api.applicants
          .register(this.obj)
          .then(() => {
            this.message = "Account creation was successfully";
            this.messageType = "success";
            this.messageShow = true;
            this.registering = false;
          })
          .catch((err) => {
            ErrorResolver.checkErrors(this, err);
          })
          .finally(() => (this.loading = false));
    },


    submitData() {
      if(this.obj.password !== this.obj.confirmPassword || this.loading || this.admission == null)
        return

      if (this.admission.applicationType.autoLoadUtme === true) {
        // validate utme first
        // validate utme
        this.loading = true;
        let vm = this;
        Api.applicants
          .validateUTME({
            registrationNumber: this.jambRegNumber,
            admissionId: this.admission.id,
          })
          .then(() => {
            this.register();
          })
          .catch((err) => {
            ErrorResolver.checkErrors(vm, err);
            this.loading = false;
          });
      } else {
        // go ahead with registration
        this.register();
      }
    },
  },


  watch: {
    admission: function (val) {
      this.$refs.form.reset()
      if (val != null && val.applicationType.modeOfEntryEnabled) {
        this.modeOfEntries = this.admission.applicationType.modeOfEntries
      }
    },
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function () {
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created() {
    this.getAdmissions();
  }
}
</script>
