import Vue from 'vue'
import nsukApplication from "./pages/nsuk/Application.vue";
import lafiaApplication from "./pages/lafia/ApplicationV2.vue";
import store from './store'
import nsukRouter from './routes'
import lafiaRouter from './routes/other';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import AppTypes from "@/shared/constants/apptypes";
import Toast from "vue-toastification";
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toastification/dist/index.css";
import vuetifyNsuk from "@/plugins/vuetify";
import vuetifyLafia from "@/plugins/lafiaPlugin";
import vuetifyFpn from "@/plugins/fpnPlugin";



if(window.API){
    // set default api
    Defaults.api = window.API
    // set up axios api
    axios.defaults.baseURL = window.API
} else{
    axios.defaults.baseURL = Defaults.api // use api for test
}

let nsuk = AppTypes.CURRENT === AppTypes.NSUK
let lafia = AppTypes.CURRENT === AppTypes.LAFIA

Vue.config.productionTip = false
if(nsuk)
    Vue.use(VueToast);
else
    Vue.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 20,
        newestOnTop: true
    })

new Vue({
    store,
    vuetify: nsuk ? vuetifyNsuk : lafia ? vuetifyLafia : vuetifyFpn,
    router: nsuk ? nsukRouter : lafiaRouter ,
    render: h => h(nsuk ? nsukApplication : lafiaApplication)
}).$mount('#app')
