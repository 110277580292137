import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Course API hook for handling courses
 * (C) 2021-2023
 *
 * */

const Course = {
    /**
     * fetch all courses depending on the programmeType, level, faculty, department, programme
     * @param {string} params required field to be passed programmeType, level, faculty, department, programme
     * @returns {Promise<object>}
     */
    all: async (params) => {
        // programme type
        let p = "?programme_type=" + params.programmeType
        // level
        if(params.level){
            p = p + "&level=" + params.level
        }
        // check faculty
        if(params.faculty){
            p = p + "&faculty=" + params.faculty
        }

        // check department
        if(params.department){
            p = p + "&department=" + params.department
        }

        // check department
        if(params.programme){
            p = p + "&programme=" + params.programme
        }

        p = p + "&strict=" + params.strict

        return axios.get(Links.course.all + p);
    },

    /**
     * fetch all result depending on programmeType, level, faculty, department, programme
     * @param {string} params required field to be passed programmeType, level, faculty, department, programme
     * @returns {Promise<object>}
     */
    allForResultsManagement: async (params) => {
        // programme typw
        let p = "?programme_type=" + params.programmeType
        // level
        if(params.level){
            p = p + "&level=" + params.level
        }
        // check faculty
        if(params.faculty){
            p = p + "&faculty=" + params.faculty
        }

        // check department
        if(params.department){
            p = p + "&department=" + params.department
        }

        // check department
        if(params.programme){
            p = p + "&programme=" + params.programme
        }

        p = p + "&strict=" + params.strict

        return axios.get(Links.course.allForResultsManagement + p);
    },

    /**
     * fetch all department depending on programmeType, department
     * @param {string} programmeType programmeType id
     * @param {string} departmentId department id
     * @returns {Promise<object>}
     */
    allDepartment: async (programmeType, departmentId) => {
        return axios.get(Links.course.allDepartment + "?programme_type=" + programmeType + '&department=' + departmentId);
    },

    /**
     * fetch all departments due for approval
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} departmentId department id
     * @returns {Promise<object>}
     */
    allDepartmentForApproval: async (sessionId, semester, departmentId) => {
        return axios.get(Links.course.allDepartmentForApproval + sessionId + '/' + semester + '/' + departmentId);
    },

    /**
     * fetch all departments at faculty level due for approval
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} departmentId department id
     * @returns {Promise<object>}
     */
    allDepartmentForApprovalAtFaculty: async (sessionId, semester, departmentId) => {
        return axios.get(Links.course.allDepartmentForApproval + sessionId + '/' + semester + '/' + departmentId);
    },

    /**
     * fetch all programmes depending on programmeType
     * @param {string} programmeId programmeType id
     * @returns {Promise<object>}
     */
    allProgramme: async (programmeId) => {
        return axios.get(Links.course.allProgramme + programmeId);
    },

    /**
     * fetch all programmes depending on programmeType, session, semester and programmeId
     * @param {string} programmeType programmeType id
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} programmeId programme id
     * @returns {Promise<object>}
     */
    allProgrammeForApproval: async (programmeType, sessionId, semester, programmeId) => {
        return axios.get(Links.course.allProgrammeForApproval + programmeType + '/' + sessionId + '/' + semester + '/' + programmeId)
    },

    /**
     * fetch all student depending on studentId, session and semester
     * @param {string} studentId userId
     * @param {string} session session id
     * @param {string} semester semester id
     * @returns {Promise<object>}
     */
    allForStudent: async (studentId, session, semester) => {
        return axios.get(Links.course.allForStudent + "?student=" + studentId +'&session=' + session + '&semester=' + semester);
    },

    /**
     * fetch all lecturer for a particular course
     * @param {string} courseId course id
     * @returns {Promise<object>}
     */
    allLecturers: async (courseId) => {
        return axios.get(Links.course.getLecturers + courseId);
    },

    /**
     * handles assigning of lecturer
     * @param {string} courseId course id
     * @param {object} data lecturer to be assigned to
     * @returns {Promise<object>}
     */
    assignLecturers: async (courseId, data) => {
        return axios.post(Links.course.assignLecturers + courseId, data)
    },

    /**
     * handle assigning of main lecturer
     * @param {string} courseId course id
     * @param {object} data information of the main lecturer to be assigned
     * @returns {Promise<boolean>}
     */
    assignMainLecturer: async (courseId, data) => {
        return axios.put(Links.course.setMainLecturer + courseId, data)
    },

    /**
     * revoke assigned lecturer for a course
     * @param {string} courseId course id
     * @param {object} data information of
     * @returns {Promise<boolean>}
     */
    unAssignLecturers: async (courseId, data) => {
        return axios.put(Links.course.unAssignLecturers + courseId, data)
    },

    /**
     * create a course
     * @param {object} data information of the course to be created
     * @returns {Promise<boolean>}
     */
    create: async (data) => {
        return axios.post(Links.course.create, data)
    },

    /**
     * update course
     * @param {string} courseId
     * @param {object} data
     * @returns {Promise<object>}
     */
    update: async (courseId, data) => {
        return axios.put(Links.course.update + courseId, data)
    },

    /**
     * delete a course
     * @param {string} courseId
     * @returns {Promise<object>}
     */
    delete: async (courseId) => {
        return axios.delete(Links.course.delete + courseId)
    },

    /**
     * fetch registered students for a course depending session, semester and courseId
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} courseId course id
     * @param {string} page page
     * @param {string} size size e.g 10
     * @returns {Promise<object>}
     */
    getRegisteredStudents: async (sessionId, semester, courseId, page, size) => {
        return axios.get(Links.course.getRegisteredStudents + courseId + '/' + sessionId + '/' + semester + "?page=" + page + "&size=" + size)
    },

    /**
     * fetch registered student information to be downloaded depending on session, semester and courseId
     * @param {string} sessionId session id
     * @param {string} semester semester id
     * @param {string} courseId course id
     * @returns {Promise<Blob>}
     */
    getRegisteredStudentsDownload: async (sessionId, semester, courseId) => {
        return axios.get(Links.course.getRegisteredStudentsDownload + courseId + '/' + sessionId + '/' + semester, {
            responseType: 'blob'
        })
    },

    /**
     * fetch all prerequisite courses
     * @param {string} courseId course id
     * @returns {Promise<object>}
     */
    allPrerequisites: async (courseId) => {
        return axios.get(Links.course.allPrerequisites + courseId)
    },

    /**
     * search for courses using course code
     * @param {string} programmeType programmeType id
     * @param {string} courseCode courseCode
     * @returns {Promise<object>}
     */
    search: async (programmeType, courseCode) => {
        return axios.get(Links.course.search + "?programme_type=" + programmeType + "&query=" + courseCode)
    },

    /**
     * add prerequisite course
     * @param {string} courseId course id
     * @param {object} data information of the course to be updated
     * @returns {Promise<object>}
     */
    addPrerequisite: async (courseId, data) =>{
        return axios.put(Links.course.addPrerequisite + courseId, data)
    },

    /**
     * remove prerequisite course
     * @param {string} courseId course id(to be removed)
     * @param {object} data information of the course to be removed
     * @returns {Promise<object>}
     */
    removePrerequisite: async (courseId, data) =>{
        return axios.put(Links.course.removePrerequisite + courseId, data)
    },

    /**
     * upload courses
     * @param {object} data blob data to be uploaded (.xlsx) format
     * @returns {Promise<Blob>}
     */
    uploadCourses: async (data) => {
        return axios.post(Links.course.uploadCourses, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * clear all registered courses
     * @param {string} semester semester id
     * @param {string} programme programme id
     * @param {string} course course id
     * @param {number} level level id
     * @param {object} removeResult
     * @returns {Promise<object>}
     */
    deleteAllRegistrations(semester, programme, course, level, removeResult){
        return axios.delete(Links.course.deregisterAll + "?programmeId=" + programme + "&semesterId=" + semester + "&courseId=" + course + "&removeResult=" + removeResult + "&levelId=" + level)
    },

    registerBulk: async (formData) => {
        return axios.post(Links.course.bulkRegistration, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    getRegisteredAdministrative: async (student, semester) => {
        return axios.get(Links.course.getRegisteredAdmin + `?student=${student}&semester=${semester}`)
    },

    unregisterAdministrative: async(formData) => {
        return axios.post(Links.course.deregisterAdmin, formData)
    }

}

export default Course
