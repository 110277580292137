<template>
  <v-text-field
    class="centered-input"
    style="text-align: center;"
    outlined
    ref="data"
    type="text"
    maxlength="1"
    tabindex="1"
    v-on="$listeners"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: "CodeInput",
  props: ["value"],
  data: () => ({}),
};
</script>
<style scoped>
input {
  box-sizing: border-box;
  text-align: center;
  border: 3px solid rgb(52, 47, 47);
  /* outline: 0; */
  appearance: none;
  outline-color: red;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  padding-left: 10px;
  margin: 5px;
}

@media (min-device-width: 962px) {
  input {
    width: 54px;
    height: 54px;
  }
}

@media (max-device-width: 961px) {
  input {
    width: 32px;
    height: 32px;
  }
}

input:hover {
  border-color: #ae8b31;
}

.centered-input >>> input {
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
}
</style>
