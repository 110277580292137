import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Session API hook, for handling sessions in the system (C) 2021-2023
 */

const Session = {
    /**
     * fetch all sessions
     * @returns 
     */
    all: async () => {
        return axios.get(Links.session.all);
    },

    /**
     * @description Gets all sessions for a programme type
     * @param {string} programmeTypeId(number)
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    allFor: async (programmeTypeId) => {
        return axios.get(Links.session.allForProgrammeType + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Returns sessions and levels
     * @deprecated to be removed. use allFor
     * @param {string} prog programme time id
     * @returns {Promise<AxiosResponse<any>>}
     */
    allForProgrammeType: async (prog) => {
        return axios.get(Links.session.allForProg + prog);
    },

    /**
     * create a session
     * @param {object} data details of the session to be created
     * @returns 
     */
    create: async(data) => {
        return axios.post(Links.session.create, data);
    },

    /**
     * update a session
     * @param {string} session session id
     * @param {object} data details of the session to be updated
     * @returns {Promise<object>}
     */
    update: async(session, data) => {
        return axios.put(Links.session.update + session, data);
    },

    /**
     * get the current session base on programmetype
     * @param {string} programmeTypeId 
     * @returns {Promise<object>}
     */
    current: async(programmeTypeId) => {
        return axios.get(Links.session.current + programmeTypeId);
    },

    /**
     * active a session i.e open a session
     * @param {object} data 
     * @returns {Promise<boolean>}
     */
    activate: async (data) => {
        return axios.put(Links.session.activate + data.id)
    },

    /**
     * de-activate a session i.e close a session
     * @param {object} data 
     * @returns {Promise<boolean>}
     */
    deactivate: async (data) => {
        return axios.put(Links.session.deactivate + data.id)
    },

    /**
     * fetch sessions a student registered for there programme
     * @param {string} studentId 
     * @returns {Promise<any[]>}
     */
    getRegisteredSessionsForStudent: async (studentId) => {
        return axios.get(Links.session.getRegisteredSessionsForStudent + '?user_id=' + studentId)
    },

    /**
     * fetch paid sessions a student paid for
     * @param {string} studentId 
     * @returns {Promise<any[]>}
     */
    getFullPaidSessionsForStudent: async (studentId) => {
        return axios.get(Links.session.getFullPaidSessionsForStudent + '?user_id=' + studentId)
    },

    /**
     * fetch a session for payment
     * @param {string} studentId userid
     * @returns {Promise<any[]>}
     */
    getSessionsForPayment: async (studentId) => {
        return axios.get(Links.session.getSessionsForPayment + '?user_id=' + studentId);
    },
}

export default Session