import axios from "axios";
import Links from "../constants/Links";


/**
 * /**
 * This is the Annoucement API hook for handling annoucement request in the system
 * (C) 2021-2023
 *
 * */


const Annoucement = {
    /**
     * @description Creates an annoucement
     * @param {object} data an object containing the following properties: title, content, startdate and enddate
     * @returns {Promise<object>} the newly created annoucement
    */
  create: async (data) => {
    return await axios.post(Links.annoucement.create, data)
  },

  /**
     * @description Updates an exsising annoucement
     * @param {string} id id of the annoucement to update
     * @param {object} data an object containing the following properties: title, content, startdate and enddate
     * @returns {Promise<object>} updated annoucement
   */
  update: async (id, data) => {
    return await axios.put(Links.annoucement.update + '/' + id, data)
  },

  /**
    * @description deletes an existing annoucement
    * @param {string} id id of the anouncement to delete
    * @returns {Promise<object>} deleted annoucement
  */
  delete: async (id) => {
    return await axios.delete(Links.annoucement.delete + '/' + id)
  },

  /**
    * Fetch all annoucement paginated
    * @param {string} page the page number of begin with (starts with zero as usual)
    * @param {string} size the size of the page
    * @returns {Promise<object>} fetch annoucement in paginated mode
  */
  fetchAllPaginated: async (page, size) => {
    return await axios.get(Links.annoucement.allPaginate + '?page=' + page + '&size=' + size)
  },


  /**
    * Fetch all annoucement paginated(Active )
    * @param {string} page the page number of begin with (starts with zero as usual)
    * @param {string} size the size of the page
    * @returns {Promise<object>} fetch annoucement in paginated mode(Active)
  */
  fetchAllActive: async () => {
    return await axios.get(Links.annoucement.allActive)
  },

  /**
   * fetch all annoucments that are public to the students
   * @returns {Promise<any[]>}
   */
  fetchPublic: async () => {
    return await axios.get(Links.annoucement.allPublic)
  }
};


export default Annoucement;
