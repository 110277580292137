import axios from "axios";
import Links from "../constants/Links";

/**
 *  This is the System API hook, for handling system (C) 2021-2023
 */

const System = {
    /**
     * @description Gets settings data for the given programme type
     * @including - levels
     * @param programmeTypeId the id of the programme type to get settings for
     *  @returns {Promise<any[]>}
     */
    all: async (programmeTypeId) => {
        return axios.get(Links.settings.all + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Resturns all roles exept for admin, student and applicant
     * @returns {Promise<any[]>} List of roles
     */
    getRoles: async () => {
        return axios.get(Links.settings.getRoles)
    },

    /**
     * @description Returns all statuses for students/applicant
     *  @returns {Promise<any[]>} List of statuses
     */
    getStatuses: async () => {
        return axios.get(Links.settings.getStatuses)
    },

    /**
     * @description Returns all payment types
     *  @returns {Promise<any[]>} Promise<AxiosResponse<>> of payment types
     */
    getPaymentTypes: async () => {
        return axios.get(Links.settings.getPaymentTypes)
    },

    /**
     * @description Initialize new settings for a programme types
     * @param {object} data object containg id of the programme type to initialize new settings for.
     *  property is: programmeTypeId
     *  @returns {Promise<object>} the new settings
     */
    newSetting: async (data) => {
        return axios.post(Links.settings.newSetting, data)
    },


    /**
     * @description Updates level with given id
     * @param {string} levelId the id of the level to update
     * @param {string} data the object containing the properties to update
     * @returns {Promise<object>} the updated level
     */
    updateLevel: async (levelId, data) => {
        return axios.put(Links.settings.updateLevel + '/' + levelId, data);
    },

    /**
     * @description Creates a new level
     * @param {object} data the object containing the the following propties: title, order and programmeTypeId
     * @returns {Promise<object>} the newly created level
     */
    createLevel: async (data) => {
        return axios.post(Links.settings.createLevel, data);
    },

    /**
     * @description Updates settings
     * @param {string} id ID of the settings to update
     * @param {object} data object containning the new propeties to update settings with. these propeties includes: passMark, probationCGPA, withdrawalCGPA and gradingSystem
     * @returns {Promise<object>} the enewly updated settings
     */
    updateSettings: async (id, data) => {
        return axios.post(Links.settings.updateSettings + '/' + id, data);
    },

    /**
     * @description Creates a new grade
     * @param {object} data the object containing the the following propties:
     * title, gradeOrder(number), gradeLimit(number), creditValue(number) and programmeTypeId(number)
     *  @returns {Promise<object>}the newly created grade
     */
     createGrade: async (data) => {
        return axios.post(Links.settings.createGrade, data);
    },

    /**
     * @description Updates and existing grade
     * @param {string} gradeId the id of the grade to update
     * @param {object} data object containing the new properties to update
     * the grade with
     * @returns  @returns {Promise<object>} the updated grade
     */

    updateGrades: async (gradeId, data) => {
        return axios.put(Links.settings.updateGrade + '/' + gradeId, data);
    },

    /**
     * @description Gets all mode of entreis for a programme type
     * @param {string} programmeTypeId the programme ID to get mode of entries for
     * @returns {Promise<object>}
     */
    modeOfEntries: async (programmeTypeId) => {
        return axios.get(Links.settings.modeOfEntries + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Creates a new mode of entry
     * @param {object} data an object containing the following properties:
     * title(string), value(string), levelId(Level Id), requireUtmeScores(checkbox true or false),
     * numberOfSemesters, programmeTypeId(Programme type id)
     * @returns {Promise<object>} the newly created mode of entry
     */
    createModeOfEntry: async (data) => {
        return axios.post(Links.settings.createModeOfEntry, data)
    },

    /**
     * @description Updates an existing mode of entry
     * @id ID of the mode of entry to update
     * @param {object} data an object containing the following properties:
     * title(string), value(string), levelId(Level Id), requireUtmeScores(checkbox true or false), programmeTypeId(Programme type id)
     * @returns {Promise<any[]>} the newly created mode of entry
     */
     updateModeOfEntry: async (id, data) => {
        return axios.put(Links.settings.updateModeOfEntry + '/' + id, data)
    },

    /**
     * update indineous state
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    updateIndigenousState: async (data) => {
        return axios.post(Links.settings.updateIndigenousState, data)
    },

    /**
     * @description Updates classes of degrees
     * @param {string} id of the class of degree to update
     * @param {object} data the object containing the following
     *  properties:
     * firstClass, secondClassUpper, secondClassLower, thirdClass and pass
     * @returns {Promise<any[]>} the updated class of degree
     */
    updateClassOfDegree: async (id, data) => {
        return axios.put(Links.settings.updateClassOfDegree + '/' + id, data);
    },

    /**
     * @description Get levels for the given programme types
     * @param {string} programmeTypeId programmeType id
     * @returns @returns {Promise<any[]>} levels
     */
    getLevels: async (programmeTypeId) => {
        return axios.get(Links.settings.getLevels + '?programme_type=' + programmeTypeId)
    },

    /**
     * update merchant details
     * @param {object} config 
     * @returns {Promise<any[]>}
     */
    updateMerchantConfig: async (config) => {
        return axios.put(Links.settings.updateMerchantConfig, config)
    },

    /**
     * fetch all merchants
     * @returns {Promise<any[]>}
     */
    getMerchantConfig: async () => {
        return axios.get(Links.settings.getMerchantConfig)
    }
}

export default System
