<template>
  <div class="toasts-wrapper" v-if="toasts.length">
        <Toast
            v-for="(toast, index) of toasts"
            :toast="toast"
            :key="index">
        </Toast>
  </div>
</template>


<script>
import Toast from "./Toast.vue"; 

export default {
  name: 'Toasts',
  components: { Toast },
  computed: {
    toasts() {
      return this.$store.state.transcriptModule.toasts; 
    }
  }
}
</script>


<style>
.toasts-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1em;
    width: 600px;
    display: flex;
    flex-direction: row;
  }
</style>