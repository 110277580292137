import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the ProfilePicture API hook, for handling profiles (C) 2021-2023
 */

const ProfilePicture = {
    /**
     * to save profile picture
     * @param {object} data profile picture to be saved
     * @returns {Promise<object>}
     */
    store: async (data) => {
        return axios.post(Links.profilePicture.store, data)
    },

    /**
     * fetch a user profile picture base on its userId
     * @param {string} userId 
     * @returns {Promise<any[]>}
     */
    retrieve: async (userId) => {
        return axios.get(Links.profilePicture.store + "?user=" + userId)
    }
}

export default ProfilePicture