import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Faculty API hook for, handling faculties in the system
 * (C) 2021-2023
 */

const Faculty = {
    /**
     * To create a faculty
     * @param {object} data 
     * @returns {Promise<any[]>}
     */
    create: async (data) => {
        return axios.post(Links.faculties.create, data)
    },

    /**
     * To update a faculty
     * @param {object} data 
     * @returns {Promise<any[]>}
     */
    update: async (data) => {
        return axios.put(Links.faculties.update, data);
    },

    /**
     * To search fo faculty
     * @param {string} id faculty(id)
     * @returns 
     */
    find: async (id) => {
        return axios.get(Links.faculties.find + id)
    },

    /**
     * Retrieve all faculties
     * @returns {Promise<any[]>}
     */
    all: async () => {
        return axios.get(Links.faculties.all)
    },

    /**
     * Retrive all faculty with deans(head of faculties)
     * @returns {Promise<any[]>}
     */
    allWithDeans: async () => {
        return axios.get(Links.faculties.allWithDeans)
    },

    /**
     * retrive faculty for the current user logged-in
     * @param {string} user userId 
     * @returns {Promise<any[]>}
     */
    forCurrentUser: async (user) => {
        return axios.get(Links.faculties.forCurrentUser + '?staff=' + user)
    }
}

export default Faculty