<template>
  <div class="" style="overflow-x: hidden">
    <v-row class="py-0 my-0" justify="space-between" style="height: 100vh; overflow-x: hidden">
      <v-col sm="4" cols="12" md="4" style="background-color: #57430E;  color: white; height: 100%; min-height: 50%;" class="pr-0 py-0 my-0 r-side"
        v-if="$vuetify.breakpoint.mdAndUp">
        <v-container style="height: 100%; " class="d-flex flex-column pr-0 r-side-overlay">
          <div v-if="isMobile" class="white--text">
            <h2>Federal University of Lafia</h2>
            <p style="color: white !important;">Integrity, Innovation, Excellence</p>
          </div>


          <div v-else class="text-left ml-md-6 white--text">
            <h5 style="font-size: 1.65em" class="pb-1 font-weight-bold">Federal University of Lafia</h5>
            <p class="" style="color: white !important;">Integrity, Innovation, Excellence</p>
          </div>

          <v-spacer></v-spacer>

          <div class="control-tabs d-flex flex-column align-content-end" style="margin-top: -20%;">
            <v-tabs v-model="tab" background-color="transparent" style="background-color: transparent" grow vertical
                    hide-slider  class="pa-0 ma-0 ml-auto">
              <v-tab :id="item.value" v-for="item in baseComponents" @click="() => { currentTab = item.children; currentTabValue = item.value }" aria-label="tab" :key="item.value"
                style="width: 100px; margin-right: 50px; border-top-left-radius: 8px; border-bottom-left-radius: 8px "
                :class="[currentTabValue === item.value ? 'white primaryDark--text md-mt-3 mt-0 pa-0 px-8 ma-0 ml-auto text-left' : 'transparent white--text pa-0 ma-0 px-8 md-mt-3 mt-0 ml-auto text-left']">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer></v-spacer>
          <div>
            <v-img class="logo ml-md-6" contain width="100" height="100" :src="logo" />
          </div>
        </v-container>
      </v-col>

      <v-col cols="12" class="d-flex flex-column pt-0 pb-0 mb-0 r-side" v-else
        style="background-color: #57430E;  color: white; height: 25%; position: relative">
        <div class="r-side-overlay pb-0 mb-0 d-flex flex-column" style="height: 100%">
          <div class="text-center white--text" style="margin-top: 10%;">
            <h3>Federal University of Lafia</h3>
            <p style="color: white !important;">Integrity, Innovation, Excellence</p>
          </div>

          <div class="mt-auto" style="width: 100%">
            <v-tabs v-model="tab" background-color="transparent" style="color: white;" centered dark icons-and-text
                     hide-slider height="40">
              <v-tab :id="item.value" v-model="tab" v-for="item in baseComponents" :key="item.value"
                     @click="() => { currentTab = item.children; currentTabValue = item.value }" aria-label="tab"
                     style="border-top-left-radius: 8px; border-top-right-radius: 8px"
                     :class="[currentTabValue === item.value ? 'white primaryDark--text px-10' : 'transparent white--text px-10']">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </div>
        </div>
      </v-col>

      <v-col class="mb-10 mb-md-4" sm="12" cols="12" md="8">
        <h4 class="text-center font-weight-bold mt-4" style="opacity: 0.7; width: 100%; color: #57430E;"
          v-if="$vuetify.breakpoint.smAndDown">{{ currentRoute }}</h4>
        <div class="text-center name-tag font-weight-bold mb-4" style="opacity: 0.7;" v-else>
          {{ currentRoute }}</div>
        <transition name="route" mode="out-in">
          <component :logo="logo" :is="currentTab" :record="record" @update-record="v => record = v" @navigate="val => currentTab = tabs[val]" />
        </transition>
      </v-col>
    </v-row>
    <announcement :show="show" :announcements="announcements" />
  </div>
</template>


<script>
import Defaults from 'shared/Defaults';
import Links from 'shared/constants/Links';
import AppTypes from 'shared/constants/apptypes';
import ErrorResolver from 'shared/ErrorResolver';
import Signup from './Signup.vue';
import Login from './Login.vue'
import ForgotPassword from "./ForgotPassword.vue";
import OTP from "./OTP.vue";
import ResetPassword from "./ResetPassword.vue";
import Api from 'main/api';
import Announcement from "@ui/components/Announcement.vue";


export default {
  name: "AuthLafia",
  components: {
    Announcement,
    Signup,
    Login
  },
  data: () => ({
    show: false,
    currentTab: 'login',
    logo: '',
    tab: null,
    currentTabValue: 'login',
    baseComponents: [
      { id: 1, title: 'Login', value: 'login', children: Login},
      { id: 2, title: 'Apply', value: 'apply', children: Signup}
    ],
    tabs: [],
    announcements: [],
    record: '' // shared record across all components
  }),
  computed: {
    currentComponent: function () {
      return this.currentTab = this.currentRoute;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    currentRoute: function () {
      return ''
    },
  },
  methods: {
    fetchGlobal() {
      const app = AppTypes.CURRENT === AppTypes.LAFIA;
      Api.global.fetch().then((res) => {
        this.logo = Defaults.api + Links.global.logo;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
    },

    loadAnnouncements(){
      Api.annoucement.fetchPublic()
        .then(res => {
          this.announcements = res.data
          this.show = (this.announcements.length > 0)
        })
    }
  },
  mounted() {

    let urlParams = new URLSearchParams(window.location.search);
    let signup = urlParams.get('apply');
    if(signup){
      this.currentTab = this.baseComponents[1].children;
      this.currentTabValue = this.baseComponents[1].value;
    }
    else{
      this.currentTabValue = 'login';
    }


    this.fetchGlobal();
  },
  created() {
    this.tabs.push(Login);
    this.tabs.push(ForgotPassword);
    this.tabs.push(OTP);
    this.tabs.push(ResetPassword);
    this.loadAnnouncements()
  },
}
</script>


<style lang="scss">

.r-side {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../resources/lafia-bg.jpg");
}

.r-side-overlay {
  background-color: rgba(87, 67, 14, 0.89);
  width: 100%;
  height: 100%;
}

.name-tag {
  position: absolute;
  width: 65%;
  top: 35px;
  text-align: center;
  color: #57430E;
  align-items: center;
}

.logo {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: flex-start;
  width: 100%;
  opacity: 1.0;
}

.v-card {
  border: 2px solid #AE8B31;
}


</style>
