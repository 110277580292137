import Vue from 'vue'
import App from './App.vue'; 
import store from "./store/index"; 
import router from './routes'
import ScrollReveal from 'scrollreveal';
 
import vuetify from '../plugins/vuetify'
import Defaults from '@/shared/Defaults';
import axios from 'axios'


//scrollReveal instance
const srv = ScrollReveal(); 

Vue.config.productionTip = false;

if(window.API){
  // set default api
  Defaults.api = window.API
  // set up axios api
  axios.defaults.baseURL = window.API
} else{
  axios.defaults.baseURL = Defaults.api // use api for test
}


//scroll-directive
Vue.directive("scroll-reveal", {
  inserted: function (el, binding) {
    const options = binding.value || {};
    srv.reveal(el.childNodes, { reset: true }, options);
  },
});



new Vue({
  store,
  vuetify,
  router,
  render: h => h(App), 
}).$mount('#app')
