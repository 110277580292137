import axios from "axios";
import Links from "../constants/Links"; 



/**
 *  This is the Programme Level API hook, for handling programme level (C) 2021-2023
 */

const ProgrammeLevel = {
    /**
     * search for programme using programme name
     * @param {string} programme programme id 
     * @param {string} level level id
     * @returns {Promise<any[]>}
     */
    find: async (programme, level) => {
        return axios.get(Links.programmeLevel.find + '?programme=' + programme + '&level=' + level)
    },

    /**
     * update programme level
     * @param {string} id programme id
     * @param {object} data information to be updated with
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.programmeLevel.update + '/' + id, data)
    }
}

export default ProgrammeLevel