import axios from "axios";
import Links from "../constants/Links";
import links from "../constants/Links";

/**
 *  This is the ProgrammeSetting API hook, for handling programme settings (C) 2021-2023
 */

const ProgrammeSettings = {
    /**
     * fetch all programme level depending on selected programme, level and semester(session -> semester)
     * @param {string} programme programme id
     * @param {string} level level id
     * @param {string} semester semester id
     * @returns {Promise<any[]>}
     */
    fetchForProgrammeLevel: async (programme, level, semester) => {
        return axios.get(Links.courseSettings.fetchForProgrammeLevel + '?programme=' + programme + '&level=' + level + '&semester=' + semester)
    },

    /**
     * fetch all unregistered programme level
     * @param {string} programme programme id
     * @param {string} level level id
     * @param {string} semester semester id
     * @returns {Promise<any[]>}
     */
    fetchUnregisteredForProgrammeLevel: async (programme, level, semester) => {
        return axios.get(Links.courseSettings.fetchUnregisteredForProgrammeLevel + '?programme=' + programme + '&level=' + level + '&semester=' + semester)
    },

    /**
     * add course the selected programme
     * @param {string} programmeId programme id
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    addCoursesToProgramme: async (programmeId, data) =>{
        return axios.post(Links.courseSettings.addCoursesToProgramme + programmeId, data)
    },

    /**
     * delete course from the selected programme
     * @param {string} programmeId programme id
     * @returns {Promise<object>}
     */
    deleteCoursesToProgramme: async (programmeId) =>{
        return axios.delete(Links.courseSettings.deleteCourseFromProgramme + programmeId)
    },

    /**
     * update programme semester settings
     * @param {string} semesterSettingId semester id
     * @param {object} data information to be updated with
     * @returns {Promise<object>}
     */
    updateProgrammeSemesterSetting: async (semesterSettingId, data) => {
        return axios.put(Links.courseSettings.updateProgrammeSemesterSetting + semesterSettingId, data )
    },

    /**
     * edit credit unit for a programme course
     * @param {object} data information to be upated
     * @returns {Promise<object>}
    */
    updateCreditUnit: async (data) => {
        return axios.post(links.courseSettings.updateCourse, data)
    },

    reEnabledCourse: async (idToEnable) => {
        return axios.put(Links.courseSettings.reEnableCourse + "/" + idToEnable)
    }
}

export default ProgrammeSettings
