<template>
    <v-sheet rounded="lg" elevation="4" :style="{borderColor: $vuetify.theme.currentTheme.primary}" outlined>
        <v-list-item two-line>
            <v-list-item-icon class="ml-auto my-auto" style="position: absolute; top: -22px;">
                <v-avatar size="40" :color="$vuetify.theme.currentTheme.secondaryLight">
                    <v-icon :color="color ? color : $vuetify.theme.currentTheme.primary" size="32">{{ 'mdi-'.concat(icon) }}</v-icon>
                </v-avatar>

            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="mb-2 text-right my-3">{{ header }}</v-list-item-title>
                <v-list-item-subtitle>{{ content }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-sheet>
</template>



<script>
export default {
    name: "BasicInfoDisplay",
    props: ['header', 'content', 'icon', 'color'],


    computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.smAndDown
        },

        isMediumAndUp: function () {
            return this.$vuetify.breakpoint.mdAndUp
        }
    }
}

</script>
