import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Nationality API hook, for handling nationalities (C) 2021-2023
 */

const Nationality = {
    /**
     * fetch all countries
     * @returns {Promise<any[]>}
     */
    countries: async () => {
        return axios.get(Links.nationality.countries)
    },

    /**
     * fetch states base selected Country
     * @param {string} country 
     * @returns {Promise<any[]>}
     */
    states: async (country) => {
        return axios.get(Links.nationality.states + country + '/states')
    },

    /**
     * fetch all countries
     * @returns {Promise<any[]>}
     */
    getCountries: async () => {
        return axios.get(Links.nationality.getCountries)
    },

    /**
     * fetch states only for admin of the system
     * @param {string} state 
     * @returns {Promise<any[]>}
     */
    getStatesAdmin: async (country) => {
        return axios.get(Links.nationality.getStates + country + '?a=1')
    },

    /**
     * Provide the country id to fetch the state respectively 
     * @param {string} country 
     * @returns {Promise<any[]>}
     */
    getStates: async (country) => {
        return axios.get(Links.nationality.getStates + country)
    },

    /**
     * provide the state id to fetch its lga respectively
     * @param {string} state 
     * @returns {Promise<any[]>}
     */
    getLgas: async (state) => {
        return axios.get(Links.nationality.getLgas + state )
    },

    /**
     * provide the state id to fetch its lga respectively(Admin only)
     * @param {string} state 
     * @returns {Promise<any[]>}
     */
    getLgasAsAdmin: async (state) => {
        return axios.get(Links.nationality.getLgas + state + '?a=1' )
    },

    /**
     * get all tribes
     * @param {string} state 
     * @returns {Promise<any[]>}
     */
    getTribes: async () => {
        return axios.get(Links.nationality.getTribes)
    },

    /**
     * provide the state id to fetch its tribes respectively (Admin only)
     * @param {string} state state id
     * @returns {Promise<any[]>}
     */
    getTribesAsAdmin: async (state) => {
        return axios.get(Links.nationality.getTribes + state + '?a=1' )
    },

    /**
     * create a country
     * @param {string} data country to be created
     * @returns {Promise<object>}
     */
    createCountry: async (data) => {
        return axios.post(Links.nationality.createCountry, data)
    },

    /**
     * create a state
     * @param {object} data state to be created
     * @returns {Promise<object}
     */
    createState: async (data) => {
        return axios.post(Links.nationality.createState, data)
    },

    /**
     * LGA to be created
     * @param {string} state 
     * @returns {Promise<any[]>}
     */
    createLga: async (data) => {
        return axios.post(Links.nationality.createLga, data)
    },

    /**
     * create a tribe
     * @param {object} data 
     * @returns {Promise<object>}
     */
    createTribe: async (data) => {
        return axios.post(Links.nationality.createTribe, data)
    },

    /**
     * update country
     * @param {string} id 
     * @param {string} data 
     * @returns {Promise<object>}
     */
    updateCountry: async (id, data) => {
        return axios.post(Links.nationality.updateCountry + '/' + id, data)
    },

    /**
     * update state 
     * @param {string} id state id 
     * @param {object} data data to be updated with
     * @returns {Promise<object>}
     */
    updateState: async (id, data) => {
        return axios.post(Links.nationality.updateState + '/' + id, data)
    },


    /**
     * update LGA
     * @param {string} id lga id 
     * @param {object} data data to be updated with
     * @returns {Promise<object>}
     */
    updateLga: async (id, data) => {
        return axios.post(Links.nationality.updateLga + '/' + id, data)
    },


    /**
     * update tribe
     * @param {string} id tribe id 
     * @param {object} data data to be updated with
     * @returns {Promise<object>}
     */
    updateTribe: async (id, data) => {
        return axios.post(Links.nationality.updateTribe + '/' + id, data)
    },


    /**
     * delete country 
     * @param {string} id country id 
     * @returns {Promise<object>}
     */
    deleteCountry: async (id) => {
        return axios.put(Links.nationality.deleteCountry + '/' + id)
    },

    /**
     * delete state 
     * @param {string} id state id 
     * @returns {Promise<object>}
     */
    deleteState: async (id) => {
        return axios.put(Links.nationality.deleteState + '/' + id)
    },

    /**
     * delete lga
     * @param {string} id state id 
     * @returns {Promise<object>}
     */
    deleteLga: async (id) => {
        return axios.put(Links.nationality.deleteLga + '/' + id)
    },
}

export default Nationality