import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Admission API hook for handling admission in the system
 * (C) 2021-2023
 *
 * */
const Admission= {
    /**
     * Get the metric of statistics
     * @param {string} faculty faculty selected
     * @param {string} session session selected
     * @returns
     */
    stats: async (faculty, session) => {
        return axios.get(Links.admission.stats + faculty + '/' + session)
    },

    /**
     * Open session for use
     * @param {string} session
     * @returns {Promise<boolean>}
     */
    enable: async (session) => {
        return axios.put(Links.admission.enable + session)
    },

    /**
     * Close session for use
     * @param {string} session
     * @returns {Promise<boolean>}
     */
    disable: async (session) => {
        return axios.put(Links.admission.disable + session)
    },

    /**
     * Retrive application types
     * @returns {Promise<any[]>}
     */
    getApplicationTypes: async () => {
        return axios.get(Links.admission.getApplicationTypes)
    },

    /**
     * Retrieve application types depending on  the selected programmeType
     * @param {string} programmeTypeId selected programmeType
     * @returns {Promise<any[]>}
     */
    getApplicationTypesFor: async (programmeTypeId) => {
        return axios.get(Links.admission.getApplicationTypesFor + '?programme_type=' + programmeTypeId)
    },

    /**
     * creation of application type
     * @param {object} data
     * @returns {Promise<any>}
     */
    createApplicationType: async (data) => {
        return axios.post(Links.admission.createApplicationType, data)
    },

    /**
     * update the application type
     * @param {string} id
     * @param {object} data
     * @returns {Promise<any>}
     */
    updateApplicationType: async (id, data) => {
        return axios.post(Links.admission.updateApplicationType + id, data)
    },

    getAdmissions: async (programmeType) => {
        return axios.get(Links.admission.getAdmissions + '?prog_type=' + programmeType)
    },

    /**
     * create admission
     * @param {object} data
     * @returns {Promise<object>}
     */
    createAdmission: async (data) => {
        return axios.post(Links.admission.createAdmission, data)
    },

    /**
     * update application
     * @param {string} id admission id
     * @param {object} data details to update
     * @returns
     */
    updateAdmission: async (id, data) => {
        return axios.post(Links.admission.updateAdmission + '/' + id , data)
    },

    /**
     * retrive active admissions
     * @returns {Promise<object>}
     */
    getActiveAdmissions: async () => {
        return axios.get(Links.admission.getActiveAdmissions)
    },

    /**
     * download admission letter
     * @param {string} applicantId applicant userId
     * @returns {Promise<blob>}
     */
    getLetter: async (applicantId) => {
        return axios.get(Links.admission.getLetter + '?q=' + applicantId, {
            responseType: 'blob'
        })
    },

    getNotification: async (applicantId) => {
        return axios.get(Links.admission.getNotification + '?q=' + applicantId, {
            responseType: 'blob'
        })
    }
}

export default Admission
