import Vuetify from 'vuetify/lib';
const FpnPlugin = new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
      },

      //for light theme
      light: {
        primary: "#DB261D",
        primaryLight: "#e1b0c1",
        primaryDark: "#b71e16",
        secondaryLight: "#a0f8ca",
        secondary: "#029241",
        basic: "#e39db5",
        linkVisited: "#AE8B31",
        error: "#CC3030",
        warning: "#FF9931",
        black: "#000000",
        drawerLinkVisited: "#CAE1EE",
        basicLight: "#fae0ba",
        success: "#07C180",
      },

      //for dark theme
      dark: {},
    },
  },
});


export default FpnPlugin;
