import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the BasicInformation API hook for handling system authentication
 * (C) 2021-2023
 *  
 * */

const BasicInformation = {
    /**
     * fetch all basic information
     * @returns {Promise<object>}
     */
    getAll: async() => {
        return axios.get(Links.basicInformation.getAll)
    },

    /**
     * update gender info
     * @param {object} data gender information to be updated
     * @returns {Promise<object>}
     */
    updateGenders: async (data) => {
        return axios.post(Links.basicInformation.updateGenders, data)
    },


    /**
     * update user titles
     * @param {object} data title to be update
     * @returns 
     */
    updateTitles: async (data) => {
        return axios.post(Links.basicInformation.updateTitles, data)
    },

    /**
     * update user religion
     * @param {object} data religion to be updated
     * @returns {Promise<object>}
     */
    updateReligions: async (data) => {
        return axios.post(Links.basicInformation.updateReligions, data)
    },


    /**
     * update user relation status
     * @param {object} data info to be updated 
     * @returns {Promise<object>}
     */
    updateRelationships: async (data) => {
        return axios.post(Links.basicInformation.updateRelationships, data)
    },

    /**
     * update user marital status
     * @param {object} data info to be updated
     * @returns {Promise<object>}
     */
    updateMaritalStatuses: async (data) => {
        return axios.post(Links.basicInformation.updateMaritalStatuses, data)
    },

    /**
     * update user genotype
     * @param {object} data info to be updated
     * @returns {Promise<object>}
     */
    updateGenotypes: async (data) => {
        return axios.post(Links.basicInformation.updateGenotypes, data)
    },
    
    /**
     * update user blood group
     * @param {object} data 
     * @returns {Promise<object>}
     */
    updateBloodGroups: async (data) => {
        return axios.post(Links.basicInformation.updateBloodGroups, data)
    },

}

export default BasicInformation