var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"mt-16 pa-2 card md-mx-0 mx-auto d-flex flex-column justify-space-between",staticStyle:{"border":"1px solid #57430E"},attrs:{"elevation":_vm.isMobile ? '1' : '1',"width":_vm.isMobile ? '350' : '450',"height":"540"}},[(_vm.registering)?_c('v-card-text',{staticClass:"pa-4"},[_c('v-select',{staticClass:"mt-3",attrs:{"dense":"","item-text":"id","required":"","rules":_vm.textRules,"outlined":"","items":_vm.admissions,"return-object":"","placeholder":"Application"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.applicationType.name + " " + item.session.name)+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.applicationType.name + " " + item.session.name)+" ")]}}],null,false,4162707292),model:{value:(_vm.admission),callback:function ($$v) {_vm.admission=$$v},expression:"admission"}}),_c('v-form',{ref:"form",model:{value:(_vm.dataValid),callback:function ($$v) {_vm.dataValid=$$v},expression:"dataValid"}},[(
                _vm.admission != null &&
                _vm.admission.applicationType.autoLoadUtme === true
              )?_c('div',[_c('v-text-field',{staticClass:"text-center mt-3",attrs:{"outlined":"","dense":"","placeholder":"UTME No. or User ID","required":"","rules":_vm.textRules},model:{value:(_vm.jambRegNumber),callback:function ($$v) {_vm.jambRegNumber=$$v},expression:"jambRegNumber"}})],1):_vm._e(),(
                _vm.admission != null &&
                _vm.admission.applicationType.modeOfEntryEnabled === true
              )?_c('div',[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.modeOfEntries,"item-text":"title","item-value":"id","outlined":"","dense":"","placeholder":"Mode of entry","required":"","rules":_vm.textRules},model:{value:(_vm.obj.modeOfEntryId),callback:function ($$v) {_vm.$set(_vm.obj, "modeOfEntryId", $$v)},expression:"obj.modeOfEntryId"}})],1):_vm._e(),_c('div',[_c('v-text-field',{staticClass:"mt-3",attrs:{"required":"","rules":_vm.emailRules,"outlined":"","dense":"","placeholder":"Email"},model:{value:(_vm.obj.emailAddress),callback:function ($$v) {_vm.$set(_vm.obj, "emailAddress", $$v)},expression:"obj.emailAddress"}})],1),_c('div',{},[_c('v-text-field',{staticClass:"mt-3",attrs:{"type":_vm.showPassword ? 'text' : 'password',"outlined":"","dense":"","placeholder":"Password","required":"","rules":_vm.passwordRules,"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.obj.password),callback:function ($$v) {_vm.$set(_vm.obj, "password", $$v)},expression:"obj.password"}})],1),_c('v-text-field',{staticClass:"mt-3",attrs:{"type":_vm.showPasswordC ? 'text' : 'password',"outlined":"","dense":"","placeholder":"Confirm Password","required":"","rules":_vm.textRules,"append-icon":_vm.showPasswordC ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPasswordC = !_vm.showPasswordC}},model:{value:(_vm.obj.confirmPassword),callback:function ($$v) {_vm.$set(_vm.obj, "confirmPassword", $$v)},expression:"obj.confirmPassword"}}),(_vm.registering)?_c('div',[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","depressed":"","height":"3em","width":"100%","disabled":!_vm.dataValid || _vm.obj.password !== _vm.obj.confirmPassword || _vm.loading || _vm.admission == null},on:{"click":_vm.submitData}},[_vm._v(" Proceed ")]),_c('div',{staticClass:"text-left mt-2"},[_vm._v(" Already applied? "),_c('a',{staticClass:"primary--text",on:{"click":(e) => {
                    e.preventDefault();
                    this.registering = !this.registering;
                  }}},[_vm._v(" Login ")])])],1):_vm._e()],1)],1):_c('v-card-text',[_c('div',{staticClass:"my-6 mx-auto text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","width":"100","height":"100","src":_vm.logo}})],1),_c('v-form',{ref:"login",attrs:{"tabIndex":"0"},model:{value:(_vm.loginValid),callback:function ($$v) {_vm.loginValid=$$v},expression:"loginValid"}},[(!_vm.registering && _vm.usingOldReg)?_c('v-alert',{attrs:{"outlined":"","type":"info","color":"primary"}},[_vm._v(" "+_vm._s(_vm.newRegMessage)+" ")]):_vm._e(),_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","required":"","prepend-icon":"mdi-account","rules":_vm.textRules,"dense":"","placeholder":"Username"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"type":_vm.showPasswordLogin ? 'text' : 'password',"append-icon":_vm.showPasswordLogin ? 'mdi-eye-off' : 'mdi-eye',"required":"","rules":_vm.textRules,"outlined":"","prepend-icon":"mdi-lock","dense":"","placeholder":"Password"},on:{"click:append":function($event){_vm.showPasswordLogin = !_vm.showPasswordLogin},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"text-left my-4"},[_c('a',{staticClass:"primary--text",on:{"click":(e) => {
                    e.preventDefault();
                    this.registering = !this.registering;
                  }}},[_vm._v("Apply")]),_vm._v(" or "),_c('a',{staticClass:"primary--text",attrs:{"href":"/login"}},[_vm._v("Reset password")])]),_c('v-btn',{attrs:{"height":"3em","depressed":"","color":"primary","width":"100%","loading":_vm.loginLoading,"disabled":!_vm.loginValid},on:{"click":_vm.login}},[_vm._v(" Login ")])],1)],1)],1)],1)],1),_c('loading-dialog',{attrs:{"show":_vm.loading}}),_c('message-box',{attrs:{"show":_vm.messageShow,"message":_vm.message,"type":_vm.messageType},on:{"close-message":function($event){_vm.messageShow = !_vm.messageShow}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }