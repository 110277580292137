export default {
    getFormatted(date, options, sep){
        function format(m) {
            let f = new Intl.DateTimeFormat('en', m);
            return f.format(date);
        }
        return options.map(format).join(sep);
    },

    getYearsArray: function(minYear){
        let min = Number(minYear)
        let max = (new Date()).getFullYear()
        let arr = []
        for(let i = min; i <= max; i++){
            arr.push(i)
        }

        return arr;
    },

    toISO(date){
        let tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };

        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' + pad(Math.abs(tzo) % 60);
    }
}