import axios from "axios";
import Links from "@/shared/constants/Links";

const DepartmentUtme = {
    create: async (data) => {
        return axios.post(Links.departmentUtme.create, data);
    },
    get: async (department) => {
        return axios.get(Links.departmentUtme.get + "?department=" + department);
    }
}

export default DepartmentUtme;
