import axios from "axios";
import Links from "../constants/Links";

const DefaultDocument = {

    /**
     * @description Create/Update(if exists) a document
     * @param {object} data a form data containing the following 
     * properties: file(a file), name(name of the document e.g advert), 
     * programme_type(Id of the programme type to associate the document with)
     * @return {Promise<Blob>}  no data on success
     */
    
    update: async (data) => {
        return axios.post(Links.defaultDocuments.update, data, {
            headers: {
                "Content-Type": "multipart/form-data"
              },
        })
    },

    /**
     * @description Returns a binary object for a file to download. 
     * NOTE: the binary object(blob) must be processed for donwload at the front end
     * @param {string} title title of the document (advert, registration_procedure, etc)
     * @param {string} programmeTypeId the programme type which the document will be fetched for
     * @returns {Promise<Blob>} binary object -> (.pdf) file
     */
    getDocument: async (title, programmeTypeId) => {
        return axios.get(Links.defaultDocuments.getDocument + '?name=' + title + '&programme_type=' + programmeTypeId, {
            responseType: 'blob'
        })
    }
}

export default DefaultDocument