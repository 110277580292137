<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card :elevation="isMobile ? '1' : '0'" :width="isMobile ? '350' : '450'" height="480"
                class="mt-md-16 md-mx-0 mx-auto" style="border: 1px solid #57430E">
          <v-card-text>
            <div class="my-6 mx-auto text-center">
              <v-img class="mx-auto" contain width="100" height="100" :src="logo"/>
            </div>
            <v-form v-model="passwordValid" @submit.prevent="onSubmit">
              <v-text-field color="secondary" variant="outlined" density="comfortable"
                            placeholder="New Password" prepend-inner-icon="mdi-dots-horizontal"
                            :type="showPassword ? 'text' : 'password'" :rules="passwordRules"
                            @click:append-inner="showPassword = !showPassword" v-model="newPassword"
                            :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye-settings'"/>
              <v-text-field color="secondary" variant="outlined" density="comfortable"
                            placeholder="Confirm Password" prepend-inner-icon="mdi-dots-horizontal"
                            :type="showPassword ? 'text' : 'password'" :rules="passwordRules"
                            @click:append-inner="showPassword = !showPassword" v-model="confirmPassword"
                            :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye-settings'"/>
              <div class="error--text" style="font-size: 8pt" v-if="passwordMismatch">Password mismatch</div>
              <div class="pt-8">
                <v-btn type="submit"
                       elevation="0"
                       :loading="loading"
                       :disabled="!passwordValid || passwordMismatch"
                       color="primaryDark" style="color: white;" width="100%"
                       height="4em">
                  Update
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>
</template>


<script>
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import ValidationRules from "@/shared/ValidationRules";
import MessageBox from "@ui/components/MessageBox.vue";

export default {
  name: 'ResetPassword',
  components: {MessageBox},
  props: ['record', "logo"],
  data: () => ({
    showPassword: false,
    form: false,
    passwordReq: {
      userid: '',
      newPassword: '',
      confirmPassword: ''
    },
    newPassword: '',
    confirmPassword: '',
    messageType: '',
    message: '',
    messageShow: false,
    loading: false,
    passwordValid: true,
    passwordRules: ValidationRules.passwordRules
  }),

  methods: {
    onSubmit() {
      this.updatePasswordForgot()
    },
    updatePasswordForgot() {
      this.loading = true
      this.passwordReq.userId = this.record
      this.passwordReq.newPassword = this.newPassword
      this.passwordReq.confirmPassword = this.confirmPassword
      Api.auth.updatePasswordForgot(this.passwordReq)
          .then(() => {
            this.message = "Password updated"
            this.messageType = 'success'
            this.messageShow = true
            this.$emit('navigate', 0)
          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    },
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },

    passwordMismatch: function(){
      return this.confirmPassword !== '' && this.newPassword !== this.confirmPassword
    },
  }
}
</script>
