import axios from "axios";
import Links from "@/shared/constants/Links";

const Faker = {
    processPayment: async (data) => {
        return axios.get(Links.faker.verifyPayment + "?invoice=" + data)
    }
}

export default Faker
