<template>
  <div class="md-md-0 mb-8" >
    <h1 v-if="!isMobile" class="mb-0" style="color: #585858; font-size: 2rem; font-weight: bold;">Applicant Login</h1>
    <h1 v-else class="text-center mb-2" style="font-size: 1.5rem; color: #585858">Applicant Login</h1>
    <p class="mb-8" v-if="!isMobile" style="color: #585858" >Login to continue application</p>
    <p v-else class="text-center" style="color: #585858; font-size:0.8rem">Login to continue application</p>

      <v-text-field
        placeholder="User ID"
        :style="{ width: isMobile ? '100%' : '100%'}"
        v-model="formData.username"
        :rules="textRules"
        outlined
        solo
      />
      <v-text-field
        placeholder="Password"
        :style="{ width: isMobile ? '100%' : '100%'}"
        class="mb-0 mt-6"
        v-model="formData.password"
        :rules="passwordRules"
        outlined
        solo
      />

     <div class="my-8">
      <v-btn
        color="primary"
        elevation="0"
        type="primary"
        :style="{ width: isMobile ? '100%' : '100%'}"
        height="2.5rem"
        class="white--text"
        :loading="isLoading"
        @click="login">
        Login
      </v-btn>
    </div>

    <confirmation-box :message="newRegMessage" positive-text="Ok" negative-text=" " title="Info"
                      :show="usingOldReg" v-on:positive="usingOldReg = false" v-on:negative="usingOldReg = false">
    </confirmation-box>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>
</template>


<script>
import ErrorResolver from "@/shared/ErrorResolver";
import Api from "@/main/api";
import MessageBox from "@ui/components/MessageBox";
import Roles from "@/shared/Roles";
import LoginUtil from "../../LoginUtil";
import ValidationRules from "@/shared/ValidationRules";
import ConfirmationBox from "@/components/ConfirmationBox.vue";


export default {
  name: 'ApplicantLogin',
  components: {
    ConfirmationBox,
    MessageBox,

  },
  data: () => ({
    textRules: ValidationRules.textRules,
    passwordRules: ValidationRules.passwordRules,
    isLoading: false,
    hasError: false,
    dataValid: true,
    errorMsg: "",
    messageType: '',
    message: '',
    messageShow: false,
    formData: {
      username: "",
      password: "",
      applicant: true
    },
    usingOldReg: false,
    newRegMessage: "",
  }),

  methods: {
    login() {
      if(this.formData.username === null || this.formData.password === null)
        return

      this.isLoading = true;
      Api.auth
        .login(this.formData)
        .then((res) => {
          // if using old reg no.
          if (res.data.old) {
            this.usingOldReg = true;
            this.newRegMessage =
              "Use your new ID: (" + res.data.applicant + ") to login";
            return;
          }
          // check
          if (!Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = "/login";
            return;
          }
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          window.location.href = "/application#/dashboard";
          // reload
          window.canReload = true;
        })
        .catch((err) => {
          ErrorResolver.checkErrors(this, err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  },
}
</script>
