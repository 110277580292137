<template>
  <v-text-field :placeholder="placeholder" v-on="$listeners" :outlined="true" :rounded="$props.rounded ? $props.rounded : true" dense flat v-bind="$attrs" :value="value" @input="$emit('input', $event)"></v-text-field>
</template>

<script>
export default {
  name: "XTextField",
  props: ['value', 'placeholder'],

  methods: {
    onInputChange(event){
      this.$emit('input', event)
      if(this.value){
        this.value = event.target.value
      }
    }
  }
}
</script>

<style scoped>

</style>
