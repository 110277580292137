<template>
  <v-card :tile="tile" :color="color" :rounded="rounded" :hover="hover" :flat="flat" :loading="loading" :elevation="Number.parseInt(elevation)" :outlined="outlined" style="cursor: auto" :max-height="maxheight" :max-width="maxwidth">
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "XCard",
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    elevation: {
      type: [Number, String],
      default: 0
    },

    maxwidth: {
      type: [String, Number],
      default: '100%'
    },

    maxheight: {
      type: [String, Number],
      default: '100%'
    },

    flat: {
      type: Boolean,
      default: false
    },

    outlined: {
      type: Boolean,
      defautl: false
    },

    rounded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },

    tile: {
      type: Boolean,
      default: true
    },

    hover: {
      type: Boolean,
      default: true
    },

    home: {
      type: Boolean,
      default: false
    }

  }
}
</script>

<style scoped>

</style>
