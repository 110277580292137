<template>
  <div class="toast" :class="toast.type">
    <!-- <div class="sidebar" :class="toast.type"></div> -->
    <div class="content">
      <div class="header">
        <h3>{{ toast.title}} </h3>
        <button @click="dismissToast">X</button>
      </div>
      <div class="text">
        <p>{{ toast.message }}</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Toast',
  props: {
    toast: { required: true, type: Object }
  },
  created() {
    // Automatically dismiss toast after 5 seconds
    setTimeout(() => {
      this.dismissToast();
    }, 5000);
  },
  methods: {
    dismissToast() {
      this.$store.commit('clearToast', this.toast.title);
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  --cream: #faf8f7;
  --light-grey: #e7e7e7;
  --grey: #cccccc;
  --white: #FFFFFF;
  --green: #14C38E;
  --red: #990000;
  --warn: #999600;
}



.toast {
    width: 20em;
    height: 5em;
    padding: 1em;
    color: var(--white);
    font-size: 0.8em;
    margin-bottom: 1em;
    justify-content: center;
    border-top-left-radius: 1em;
    border-bottom-right-radius: 1em;
    border-top-right-radius: .8em;
    box-shadow: 0 0 .5em var(--grey);
    z-index: 999;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }


    /** MOZILLA BROWSER */
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    p {
        font-weight: 100;
        margin-top: 2px;
        color: var(--white);
    }


    .header {
        display: flex;
        text-decoration: none;
        border: none;
        cursor: pointer;
        font-size: 1em;

        button {
            margin-left: auto;
            :hover {
                color: var(--grey);
            }
        }
    }

    &.success {
        background-color: var(--green);
        color: white;
    }

    &.info {
        background-color: var(--red);
        color: var(--white);
    }


    .error {
        background-color: var(--red);
        color: var(--white);
    }

    .content {
        padding-left: 0.8em;
        color: var(--white);
    }
}
</style>
