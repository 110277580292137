import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Semester API hook, for handling semester in the system (C) 2021-2023
 */

const Semester = {
    /**
     * update semester
     * @param {string} semester 
     * @param {object} data 
     * @returns {Promise<object>}
     */
    update: async (semester, data) => {
        return axios.put(Links.semester.update + semester, data)
    },

    /**
     * activate a semester i.e open a semester 
     * @param {string} semester 
     *@returns {Promise<boolean>} 
     */
    activate: async (semester) => {
        return axios.put(Links.semester.activate + semester)
    },

    /**
     * close a semester i.e close a semester
     * @param {string} semester 
     * @returns {Promise<boolean>}
     */
    deactivate: async (semester) => {
        return axios.put(Links.semester.deactivate + semester)
    },
}

export default Semester