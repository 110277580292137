<template>
  <div>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="showMini" :permanent="isLarge"  :color="bg" app>
      <v-list dense dark class="d-flex flex-column">
        <v-list-item class="pb-5 pl-2" dense @click="$router.push(items.dashboard ? items.dashboard : '/')" key="Dashboard" v-if="!showMini">
          <v-list-item-icon class="my-0 pb-0 pl-0 mr-3 pr-0">
            <v-img
                width="70"
                contain
                height="70"
                class="rounded-circle"
                :src="image"
            />
          </v-list-item-icon>
          <v-list-item-content class="my-0 pb-0">
            <div class="pa-1" style="border: 1px solid rgba(245,245,245,0.46); border-radius: 4px">
              <v-list-item-title class="my-0">Welcome back!</v-list-item-title>
              <v-list-item-subtitle class="my-0">{{userId.toUpperCase()}}</v-list-item-subtitle>
              <v-list-item-subtitle class="my-0">{{(new Date()).toDateString()}}</v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="!showMini"/>


        <v-list-item dense v-for="item in filteredItems" :key="item.url" :to="item.url" class="py-0"
        v-show="!item.roles || (getUser != null && (checkRole(item.roles, getUser.user.roles)) || (item.url === 'manage_results' && isResultAccessOpened()))">
          <v-list-item-icon>
            <v-icon color="white" :size="iconSize">{{ item.icon }}</v-icon>
          </v-list-item-icon>


          <v-list-item-content>
            <v-list-item-title class="white--text">{{ getTitle(item) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="items.settings"></v-divider>
        <v-menu bottom min-width="200px" rounded offset-y v-if="items.settings">
          <template v-slot:activator="{ on }">
            <v-list-item class="py-0" v-on="on">
              <v-list-item-icon>
                <v-icon color="white" :size="iconSize">{{ 'mdi-cog' }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text">{{ 'Settings' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-card>
            <v-list light>
              <v-list-item v-for="item in items.settings" :key="item.url" :to="item.url">
                <v-list-item-icon>
                  <v-icon color="primary" :size="iconSize">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>
import Logo from "../../Logo.vue";
import {mapGetters} from "vuex";
import Defaults from "@/shared/Defaults";
import Roles from "@/shared/Roles";
import AppTypes from "@/shared/constants/apptypes";

export default {
  name: "Sidebar",
  components: {
    Logo
  },
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true
    },

    sidebarClassName: {
      type: String,
      default: ''
    },

    open: Boolean,

    mini: Boolean,

    items: Object
  },

  computed: {
    isLarge: function() {
      //this.$vuetify.breakpoint.lg
      return this.$vuetify.breakpoint.lg
    },

    showMini: function() {
      return this.open && this.isLarge
    },

    userId: function(){
      if(!this.getUser){
        return ''
      }

      return this.getUser.user ? this.getUser.user.userId : this.getUser.userId;
    },

    ...mapGetters(['getUser']),

    ...mapGetters(['getUser', 'getGlobalConfig', 'getSuper']),
    userLoaded: function(){
      return this.getUser != null
    },

    showManagement: function(){
      return this.getUser != null &&
          this.managements.filter(e => this.checkRole(e.roles, this.getUser.user.roles))
              .length > 0
    },

    hasSubCommittee: function(){
      return this.getGlobalConfig != null && this.getGlobalConfig.examSubCommitteeEnabled
    },

    filteredItems: function(){
      return this.items.managements.filter(e => e.url !== 'manage_results_subsenate' || (e.url === 'manage_results_subsenate' && this.hasSubCommittee) || e.url !== 'staff_management')
    }
  },

  watch: {
    open: function(newVal) {
      this.iconSize = newVal ? 14 : 18;
      this.drawer = newVal;
    },

    getUser: function(newVal){
      if(newVal != null){
        this.image = `${Defaults.api}/profile-picture/fetch?user=${this.getUser.userId}`;
      }
    },
  },


  data: () => ({
    showOptionsMenu: false,
    drawer: true,
    logo: '',
    iconSize: 18,
    image: '',
    bg: '',
  }),

  methods: {
    checkRole(roles, uroles){
      return this.getUser != null && Roles.deepContains(roles, uroles);
    },

    isResultAccessOpened: function(){
      return this.getUser != null && this.getUser.department != null && this.getUser.department.openedForAccess === true;
    },

    getTitle(item){
      if(AppTypes.CURRENT === AppTypes.FPN){
        if(item.title === 'Schools')
          return 'Institution'
        else if(item.title === "Faculties")
          return 'Schools'
        else if(item.title === "Faculty Deans")
          return 'School Deans'
      }

      return item.title
    }
  },

  created(){
    if(this.getUser != null)
      this.image = `${Defaults.api}/profile-picture/fetch?user=${this.getUser.userId}`;

    this.bg = AppTypes.LAFIA === AppTypes.CURRENT ? 'primaryDark' : 'primaryDark'
  },

}
</script>


<style lang="scss" scoped>
.lafia-sidebar {
  display: flex;
  min-height: 0;
  height: 100%;
  width: fit-content;

}


.secondary-sidebar {
  overflow-y: auto;
  height: 100%;
}
</style>
