<template>
  <v-app style="width: 100%">
    <v-app-bar dense color="white" v-if="isMobile" :app="true" elevate-on-scroll>
      <v-list-item class="mr-0 ml-0 pl-0">
        <v-list-item-icon>
           <v-img contain aspect-ratio="1" alt="logo" width="50px" src="../../resources/fpn/logo.svg"></v-img>
        </v-list-item-icon>

        <v-list-item-title class="title ">FPN</v-list-item-title>
      </v-list-item>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main style="width: 100%; overflow-x: hidden;" :class="{'g': isMobile,'q': isMediumAndUp}">
      <Login class="mt-10 mt-md-0"/>
    </v-main>
  </v-app>
</template>

<script>
import AppTypes from 'shared/constants/apptypes';
import Api from '@/main/api';
import Login from '../fpn/components/Auth.vue';
import Defaults from '@/shared/Defaults';
import ErrorResolver from '@/shared/ErrorResolver';
import LoginUtil from "@/login/LoginUtil";
import Links from '@/shared/constants/Links';


export default {
  name: 'App',

  components: {
    Login,
  },

  data: () => ({
    logo: '',
    title: ""
  }),

  methods:{
    fetchGlobal() {
      const app = AppTypes.FPN === AppTypes.FPN;
      Api.global.fetch().then((res) => {
        this.logo = Defaults.api + Links.global.logo;

        if(app){
          document.title = res.data.institutionName;
        }

        return undefined;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
    },

    loadScripts(){
      Api.support.all()
        .then((res) => {
          for (let i = 0; i < res.data.length; i++){
            eval(res.data[i].script)
          }
        })
        .catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => {
          this.loading = false;
        })
    }
  },

  mounted() {
    this.fetchGlobal();
  },


  computed: {
    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  },

  created() {
    LoginUtil.resetAccessToken()
    LoginUtil.resetRefreshToken()
    this.loadScripts()
  },

};
</script>

<style>

 @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.g{
    background-color: #F5F5F5;
  }

  .q{
    background-color: white;
  }

  body{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;

  }
</style>
