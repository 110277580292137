<template>
  <div style="width: 100%; height:100%" >
    <div class="flex flex-column justify-space-around align-content-center">
      <div  v-if="!isMfa">
          <h1
              v-if="!isMobile"
              class="mb-2 font-weight-black font-weight-bold text-xl"
              style="color: #585858; font-weight: bold;"
              :style="{ fontSize: isMobile ? '1.5rem' : '2rem', fontWeight: 'bold'}">
            Login
          </h1>
        <h1 v-else class="text-center" style="color: #585858; font-weight: bold;">
          Login
        </h1>
          <p class="mb-12" v-if="!isMobile" style="color: #585858" >Login to your account using your credentials</p>
          <p v-else class="text-center" style="color: #585858; font-size:0.8rem">Login to your account using your credentials</p>
          <div class="mb-4">
            <div class="font-weight-black font-weight-bold" style="color: black !important; margin-bottom: .4em;">Email address/User ID</div>
            <v-text-field outlined solo class="" placeholder="Email/Username" v-model="formData.username"></v-text-field>
            <div class="font-weight-black font-weight-bold" style="color: black !important; margin-bottom: .4em;">Password</div>
            <v-text-field
              full-width
              outlined solo
              placeholder="Password" class="mb-0 mt-2"
              v-model="formData.password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ?'mdi-eye-circle-outline' : 'mdi-eye-circle'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="login"
            />
          </div>
          <div class="mb-4">
            <v-btn
              color="primary"
              elevation="0"
              type="primary"
              width="100%"
              class="white--text font-weight-bold"
              :loading="isLoading"
              large
              @click="login">Login</v-btn>
          </div>
      </div>

      <div v-else>
          <h1 class="mb-6" style="color: #585858; font-size: 2rem; font-weight: bold; ">OTP</h1>
          <p v-if="$vuetify.breakpoint.mdAndUp">Extra security is required.</p>
          <div class="mb-4">
            <v-otp-input
              length="6"
              v-model="mfas"
              :disabled="loading"
            />
          </div>
          <div class="mb-4">
            <v-btn
              color="primary"
              elevation="0"
              type="primary"
              width="100%"
              class="white--text font-weight-bold"
              :loading="isLoading"
              large
              @click="verifyCode">Verify</v-btn>
          </div>
      </div>
    </div>
    <confirmation-box :message="newRegMessage" positive-text="Ok" negative-text=" " title="Info"
                      :show="usingOldReg" v-on:positive="usingOldReg = false" v-on:negative="usingOldReg = false">
    </confirmation-box>
    <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
  </div>
</template>

<script>

import ErrorResolver from "@/shared/ErrorResolver";
import Api from "@/main/api";
import MessageBox from "@ui/components/MessageBox";
import Roles from "@/shared/Roles";
import LoginUtil from "../../LoginUtil";
import XButton from "@ui/widgets/XButton.vue";
import XTextField from "@ui/widgets/XTextField.vue";
import ConfirmationBox from "@/components/ConfirmationBox.vue";

export default {
  name: 'Login',
  components: {ConfirmationBox, XTextField, XButton, MessageBox},
  props: ['currentPage'],
  emits: ['close', 'application'],
  data: () => ({
    isLoading: false,
    loading: false,
    hasError: false,
    errorMsg: "",
    mfaMsg: "",
    mfaSent: false,
    messageType: '',
    message: '',
    messageShow: false,
    usingOldReg: false,
    newRegMessage: '',
    authMode: 0,
    reader: null,
    deviceConnected: false,
    showPassword: false,
    stateGlobal: {},
    formData: {
      username: "",
      password: ""
    },
    mfa: {
      userId: '',
      code: '',
      recovery: null
    },
    mfas: '',
    mfaLength: 6,
    usingRecovery: false,

    isMfa: false, // for multi factor auth
  }),

  methods: {
    login() {
      if (this.formData.password === undefined || this.formData.password === '' || this.formData.username === undefined || this.formData.username === '')
        return
      // start loading
      this.isLoading = true;
      this.usingOldReg = false;

      Api.auth.login(this.formData).then(res => {

        this.hasError = false;
        // if using old reg no.
        if(res.data.old){
          if (res.data.applicant) { //
            this.usingOldReg = true;
            this.newRegMessage = "Use your new ID: (" + res.data.applicant + ") to login";
          } else if (res.data.message){ // change of programme
            this.usingOldReg = true;
            this.newRegMessage = "Use the username: " + res.data.message + " as you new username";
          } else if (res.data.userId) { //
            this.usingOldReg = true;
            this.newRegMessage = "Login using the following ID: " + res.data.userId + '. Password remains same as your applicant password'
          }
        }
        // if multi factor enabled
        else if (res.data.mfa) {
          this.isMfa = true
          this.mfa.userId = res.data.userId
        } else {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          if (Roles.contains(res.data.roles, Roles.Admin) || Roles.contains(res.data.roles, Roles.SuperAdmin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student) || Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }

      }).catch(err => {
        this.hasError = true
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false;
      })
    },

    verifyCode() {
      if((this.usingRecovery ? !this.mfa.recovery : this.mfas.length !== 6))
        return

      this.isLoading = true
      this.mfa.code = this.usingRecovery ? this.mfa.recovery : this.mfas
      Api.auth.verifyMfa(this.mfa, this.usingRecovery)
        .then(res => {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt)
          LoginUtil.setRefreshToken(res.data.refreshToken);

          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }).catch(err => {
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },

    fetchGlobal() {
      Api.global.fetch().then((res) => {
        this.stateGlobal = res.data;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => this.loading = false)
    },
  },

  computed: {
    isMobile: function(){
      return this.$vuetify.breakpoint.smAndDown
    },

    isMediumAndUp: function(){
      return this.$vuetify.breakpoint.mdAndUp
    },
  }
}
</script>
