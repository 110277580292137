<template>
  <transition :name="transition">
    <div tabindex="0"
         class="vl-overlay vl-active"
         :class="{ 'vl-full-page': isFullPage }"
         v-show="isActive"
         :aria-busy="isActive"
         aria-label="Loading"
         :style="{ zIndex }"
    >
      <div class="vl-background"
           @click.prevent="cancel"
           :style="bgStyle"
      />
      <div class="vl-icon">
        <slot name="before"/>
        <slot name="default">
          <component
            :is="loader"
            :color="color"
            :width="width"
            :height="height"
          />
        </slot>
        <slot name="after"/>
      </div>
    </div>
  </transition>
</template>



<script>
import { defineComponent, render } from 'vue'; 
import {MayBeHTMLElement, removeElement} from './hooks/dom'
import Loaders from './components';

export default defineComponent({
  name: 'Loading', 
  mixins: [], 
  props: {
    active: Boolean,
    programmatic: Boolean,
    isFullPage: {
      type: Boolean,
      default: true
    },
    container: [Object, Function, MayBeHTMLElement],
    enforceFocus: {
      type: Boolean,
      default: true
    },
    lockScroll: Boolean,
    transition: {
      type: String,
      default: 'fade'
    },

    canCancel: Boolean,
    onCancel: {
      type: Function,
      default: () => {
      }
    },
    color: String,
    backgroundColor: String,
    opacity: Number,
    width: Number,
    height: Number,
    zIndex: Number,
    loader: {
      type: String,
      default: 'spinner'
    }
  }, 
  components: Loaders, 
  emits: ['hide', 'update:active'], 
  data() {
    return {
      isActive: this.active
    }
  },

  mounted() {

  }, 

  methods: {
    cancel() {
      if (!this.canCancel || !this.isActive) return;
      this.hide();
      this.onCancel.apply(null, arguments);
    },

    hide() {
      this.$emit('hide');
      this.$emit('update:active', false);

      if (this.programmatic) {
        this.isActive = false;

        // Timeout for the animation complete before destroying
        setTimeout(() => {
          const parent = this.$el.parentElement;
          // unmount the component
          render(null, parent);
          removeElement(parent)
        }, 150)
      }
    },

    disableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.add('vl-shown');
      }
    },

    enableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.remove('vl-shown');
      }
    },

    keyPress(event) {
      // todo keyCode is deprecated
      if (event.keyCode === 27){
        this.cancel()
      }
    },
  },

  watch: {
    active(value) {
      this.isActive = value
    },
    isActive: {
      handler (value)  {
        if (value) {
          this.disableScroll();
        } else {
          this.enableScroll()
        }
      },
      immediate: true
    }
  },

   computed: {
    bgStyle() {
      return {
        background: this.backgroundColor,
        opacity: this.opacity,
      }
    }
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.keyPress);
  },
})
</script>

<style>

.vl-shown {
  overflow: hidden;
}

.vl-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vl-overlay.vl-active {
  display: flex;
}

.vl-overlay.vl-full-page {
  z-index: 9999;
  position: fixed;
}

.vl-overlay .vl-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vl-overlay .vl-icon, .vl-parent {
  position: relative;
}
</style>