<template>
  <v-app style="background-color: #F5F5F5">
      <v-app-bar :app="true" elevate-on-scroll color="white" class="flex-shrink-1" dense>
        <!-- -->
        <v-list-item-icon class="my-auto">
          <v-img class="my-auto" width="32px" height="32px" aspect-ratio="1" alt="lg" :src="logo"></v-img>
        </v-list-item-icon>

        <div class="font-weight-bold">
          {{ getGlobal.shortName }} - Verification
        </div>

        <v-spacer></v-spacer>
        <div class="nav-actions">
          <router-link to="/profile" class="ml-3" active-class="basic--text" v-if="!isMobile && getUser != null">Profile</router-link>
          <router-link to="/payment" class="ml-3" active-class="basic--text" v-if="!isMobile && isStudent">Payments
          </router-link>
          <router-link to="/course" class="ml-3" active-class="basic--text" v-if="!isMobile && isStudent">Course registrations</router-link>

          <template v-if="isMobile">
            <v-menu close-on-click class="">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-on="on" v-bind="attrs">mdi-menu</v-icon>
              </template>

              <v-list v-if="isMobile">
                <v-list-item key="profile" link to="/profile" v-if="getUser != null">Profile</v-list-item>
                <v-list-item key="payment" link to="/payment" v-if="isStudent">Payments</v-list-item>
                <v-list-item key="course" link to="course" v-if="isStudent">Registration
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-container style="height: 100%">
        <router-view></router-view>
      </v-container>
    </v-main>
    <loading-dialog :show="loading"></loading-dialog>
    <message-box
        :show="messageShow"
        :message="message"
        :type="messageType"
        v-on:close-message="messageShow = !messageShow"
    ></message-box>
  </v-app>
</template>

<script>

//payment_details
//course_registration
import {mapActions, mapGetters} from "vuex";
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import LoadingDialog from "@ui/components/LoadingDialog";
import MessageBox from "@ui/components/MessageBox";
import Defaults from "@/shared/Defaults";
import Links from "@/shared/constants/Links";


export default {
  name: "Verification",
  components: {MessageBox, LoadingDialog},
  data: () => ({
    image: '', //user image
    mini: false,
    viewOption: 1,
    locked: false,
    counter: 0,
    title: "",
    messageType: '',
    message: '',
    messageShow: false,
    loading: false,
    logo: Defaults.api + Links.global.logo,
  }),

  methods: {
    ...mapActions(['loadGlobalConfig']),
    loadData() {
      Api.global.fetch()
          .then(res => {
            this.$store.commit('setGlobal', res.data);

          }).catch(err => ErrorResolver.checkErrors(this, err))
    },

    loadUser(){
      this.loading = true
      Api.students.find(window.DATA)
          .then(res => {
            this.$store.commit('setCurrentUser', {user: res.data})
            window.DATA = undefined;

          }).catch(err => ErrorResolver.checkErrors(this, err))
          .finally(() => this.loading = false)
    }
  },

  computed: {
    ...mapGetters(['getGlobal', 'getUser']),

    isStudent: function(){
      return this.getUser != null && this.getUser.temporal === undefined
    },

    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    open: function (newVal) {
      this.iconSize = newVal ? 14 : 18;
    },
  },

  created() {
    this.loadUser();
    this.loadData();
    // make sure data is provided
    if(!window.DATA){
      window.location.href = 'not_found'
      return;
    }
    // if just loaded, go to payment route
    const url = window.location.href
    if (url.indexOf('#/') < 1 || (url.substring(url.indexOf('#/')).length - 2) === 0) {
      this.$router.push('profile')
    }

    this.loadData()
   this.loadUser()
  }

}
</script>
