import axios from "axios";
import Links from "../constants/Links";

/**
 *  This is the Signature API hook, for handling signature in the system (C) 2021-2023
 */

const Signature = {
    /**
     * create a signature for the student
     * @param {object} data signature of the student
     * @returns 
     */
    setSignature: async (data) => {
        return axios.post(Links.signature.setSignature, data)
    },

    /**
     * fetch the student signatory
     * @param {string} userId student userid
     * @returns {Promise<object>}
     */
    getSignature: async (userId) => {
        return axios.get(Links.signature.getSignature + userId)
    }
}

export default Signature