<template>
  <div class="doc-page d-flex mx-auto flex-row">
    <loading-dialog :show="loading" />
  </div>
</template>
<script>
import LoadingDialog from "@ui/components/LoadingDialog";
import Api from "@/main/api";

export default {
  name: "Payment",
  components: {
    LoadingDialog
  },

  data: () => ({
    loading: false,
  }),


  mounted() {
    this.loading = true
    if(window.DATA){
      Api.faker.processPayment(window.DATA)
        .finally(() => window.history.back())
    } else{
      window.history.back()
    }
  }
}
</script>


<style lang="scss" scoped>

</style>
