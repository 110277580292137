import axios from "axios"
import Links from "../constants/Links"

/**
 * This is the Honour API hook, for handling Honours (C) 2021-2023
 * @description Contains api calls to honours endpoints
 */
const Honour = {
    /**
     * @description Creates a new Honour
     * @param {object} data an object containing the following properties (name, value(number), programmeTypeId(id of the programme type))
     * @returns {Promise<object>} the newly created honour
     */
    create: async (data) => {
        return axios.post(Links.honours.create, data)
    },

    /**
     * @description Updates an existing Honour
     * @param id of the honour to update
     * @param {object} data an object containing the following properties (name, value(number), programmeTypeId(id of the programme type))
     * @returns {Promise<object>} the newly created honour
     */
     update: async (id, data) => {
        return axios.put(Links.honours.update + '/' + id, data)
    },

    /**
     * Returns all honours based on the supplied programme type
     * @param {string} programmeTypeId the id of the programme type to fetch for
     * @returns {Promise<object>} List of honours
     */
    fetch: async (programmeTypeId) => {
        return axios.get(Links.honours.fetch + '?programme_type=' + programmeTypeId)
    }
}

export default Honour