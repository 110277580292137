import Vue from 'vue'
import vuetifyNsuk from '../plugins/vuetify'
import vuetifyLafia from '../plugins/lafiaPlugin';
import vuetifyFpn from '../plugins/fpnPlugin';
import AppNsuk from './nsuk/App.vue'
import AppLafia from './lafia/App.vue';
import AppFpn from './fpn/App.vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import AppTypes from "@/shared/constants/apptypes";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

if(window.API){
  // set default api
  Defaults.api = window.API
  // set up axios api
  axios.defaults.baseURL = window.API
} else{
  axios.defaults.baseURL = Defaults.api // use api for test
}

let nsuk = AppTypes.CURRENT === AppTypes.NSUK;
let lafia = AppTypes.CURRENT === AppTypes.LAFIA;

Vue.config.productionTip = false;

if(nsuk)
  Vue.use(VueToast);

else if(!nsuk)
  Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
  })

new Vue({
  vuetify: nsuk ? vuetifyNsuk : lafia ? vuetifyLafia : vuetifyFpn,
  render: h => h(nsuk ? AppNsuk : lafia ? AppLafia : AppFpn)
}).$mount('#app')
