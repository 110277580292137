import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Student Information API hook for handling Student Information in the system (C) 2021-2023
 * 
 * */


const StudentInformation = {
    /**
     * fetch student information
     * @param {string} id student userId
     * @returns {Promise<object>}
     */
    getInformation: async (id) => {
        return axios.get(Links.studentInformation.getInformation + '?id=' + id)
    },

    /**
     * update student information
     * @param {string} id student id
     * @param {object} data info to be updated
     * @returns 
     */
    updateInformation: async (id, data) => {
        return axios.put(Links.studentInformation.updateInformation + '?id=' + id, data)
    },

    /**
     * 
     * @param {string} id 
     * @returns 
     */
    getIDCardDetails: async (id) => {
        return axios.get(Links.studentInformation.getIDCardDetails + '?id=' + id)
    },

    /**
     * download student information (.pdf)
     * @param {string} studentId user id of the student
     *@returns {Promise<Blob>} 
     */
    download: async (studentId) => {
        return axios.get(Links.studentInformation.download + '?student=' + studentId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads ID cards fro students (.pdf)
     * @param reqParams an object containing the following as ID's:session,  faculty, department, programme, level. all except session are optional
     * @returns {Promise<any[]>} a pdf document
     */
    cards: async (reqParams) => {
        let params = '?session=' + reqParams.session

        if(reqParams.faculty){
            params = params + '&faculty=' + reqParams.faculty
        }

        if(reqParams.department){
            params = params + '&department=' + reqParams.department
        }

        if(reqParams.programme){
            params = params + '&programme=' + reqParams.programme
        }

        if(reqParams.level){
            params = params + '&level=' + reqParams.level
        }

        if(reqParams.paymentDate){
            params = params + '&date=' + reqParams.paymentDate
        }
        return axios.get(Links.studentInformation.cards + params, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads ID for a single student(.pdf)
     * @param {string} studentId the ID of the student to download ID card for
     * @returns {Promise<Blob>} a pdf document
     */
    cardSingle: async (studentId) => {
        return axios.get(Links.studentInformation.cardSingle + '?student=' + studentId, {
            responseType: 'blob'
        })
    },
}

export default StudentInformation
