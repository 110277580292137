import axios from "axios";
import Links from "../constants/Links";



/**
 * This is the Requiremnts API hook, for handling all the system requirements in the system (C) 2021-2023
 */

const Requirements = {
    /**
     * fetch all SSCE requirements from the system
     * @returns {Promise<object>}
     */
    getSsceRequirements: async () => {
        return axios.get(Links.requirements.getSsceRequirements)
    },

    /**
     * update all SSCE grades for the system
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateSSCEGrades: async (data) => {
        return axios.post(Links.requirements.updateSSCEGrades, data)
    },

    /**
     * update all SSCE excam types in the system
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateSSCEExamTypes: async (data) => {
        return axios.post(Links.requirements.updateSSCEExamTypes, data)
    },

    /**
     * update all SSCE organization involved in the system
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    updateSSCEOrganization: async (data) => {
        return axios.post(Links.requirements.updateSSCEOrganization, data)
    },

    /**
     * fetch all required qualifications details from the system
     * @returns {Promise<object>}
     */
    getQualificationDetails: async () => {
        return axios.get(Links.requirements.getQualificationDetails)
    },

    /**
     * update qualification grades
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateQualificationGrade: async (data) => {
        return axios.post(Links.requirements.updateQualificationGrade, data)
    },

    /**
     * update application qualification details
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateApplicantQualification: async (data) => {
        return axios.post(Links.requirements.updateApplicantQualification, data)
    },

    /**
     * fech institution types
     * @returns {Promise<any[]>}
     */
    getInstitutionTypes: async () => {
        return axios.get(Links.requirements.getInstitutionTypes)
    },

    /**
     * update institution types in the system
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    updateInstitutionTypes: async (data) => {
        return axios.post(Links.requirements.updateInstitutionTypes, data)
    },

    /**
     * fetch required subjects 
     * @returns {Promise<any[]>}
     */
    getSubjects: async () => {
        return axios.get(Links.requirements.getSubjects)
    },

    /**
     * update subjects
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateSubject: async (data) => {
        return axios.post(Links.requirements.updateSubject, data)
    },

    /**
     * update UTME details of a user(student)
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateUserUTMEDetails: async(data) => {
        return axios.post(Links.requirements.updateUserUTMEDetails, data)
    },

    /**
     * update SSCE details of a user(student)
     * @param {object} data the information to be updated
     * @returns {Promise<object>}
     */
    updateUserSSCEDetails: async(data) => {
        return axios.post(Links.requirements.updateUserSSCEDetails, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },
}

export default Requirements