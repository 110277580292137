<template>
  <v-btn
      :color="color"
      :text="text"
      :outlined="outline"
      :elevation="elevation"
      :depressed="depressed"
      :tile="tile"
      :width="width"
      :fab="fab"
      :x-large="xLarge"
      :height="height"
      :x-small="xSmall"
      :large="large"
      :small="small"
      :rounded="rounded"
      :disabled="disabled"
      :loading="loading"
      v-on="$listeners"
      :icon="icon">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: "XButton",
  props: {
    color: {
      type: String,
      default: ''
    },
    text: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: String,
      default: '0'
    },
    depressed: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },

    width: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
