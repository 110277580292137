import Vue, { reactive } from 'vue'; 
import VueRouter from 'vue-router'


Vue.use(VueRouter);

const status = reactive({ loading: true }); 


const routes = [
    { 
      path: "/", 
      name: 'home', 
      component: () => import("../pages/Home.vue") , 
    },
    { 
      path: "/login", 
      name: 'login', 
      component: () => import("../pages/Login.vue") 
    },
    {
      path: "/info",
      name: 'info',
      component: () => import("../pages/Account/Info.vue"),
    },

    {
      path: "/success",
      name:'success',
      component: () => import("../pages/Account/Success.vue"),
    },
    {
      path: "/signup",
      name: 'signup',
      component: () => import("../pages/Account/Signup.vue"),
    },
    {
      path: "/account",
      name: 'account', 
      component: () => import("../pages/Account/Account.vue"),
    },
    {
      path: "/status",
      name: 'status',
      component: () => import("../pages/Dashboard/Status.vue"),
    },

    {
      path: "/delivery",
      name: 'delivery', 
      component: () => import("../pages/Dashboard/Delivery.vue"),
    },

    {
      path: "/procedure",
      name: 'procedure', 
      component: () => import("../pages/Dashboard/Procedure.vue"),
    },

    {
      path: '/verify', 
      name: 'verify', 
      component: () => import("../pages/Dashboard/Verification.vue")
    },

    {
      path: '*', 
      name: 'notfound',
      component: () => import("../pages/404.vue")
    }
]

const router = new VueRouter({
  mode: 'hash', 
  routes, 
  scrollBehavior: (to, from, savedPosition) => {
    if(to){
      return {
        el: to.hash, 
        behavior: 'smooth'
      }
    }else {
      return { x: 0, y: 0}; 
    }
  }
}); 

Object.defineProperty(router, 'status', {
  get: () => status, 
  set: value => (status.loading = value),
})


router.beforeEach((to, from, next) => {
  router.status = true; 
  next(); 
}); 

router.afterEach((to, from) => {
  router.status = false; 
})


export default router

