import axios from "axios";
import Links from "../constants/Links";


/**
 *  This is the ProgrammeChange API hook, for handling programmechange (C) 2021-2023
 */

const ProgrammeChange = {
    /**
     * @description Gets configuration for change of programme. only applies for student. applicant config is defined in application type settings
     * @returns {Promise<AxiosResponse<any>>} An object containing the config
     */
    getConfig: async () => {
        return axios.get(Links.programmeChange.getConfig)
    },
    /**
     * @description Updates programme change config.
     * @param {object} data The object containing the following properties: fee
     * @returns {Promise<AxiosResponse<any>>} A new updated config
     */
    updateConfig: async (data) => {
        return axios.put(Links.programmeChange.updateConfig, data);
    },
    /**
     * initializes transaciont for applicant change of programme application
     * @param {object} data The payload for the request. required properties are
     * userId and newProgrammeId. The applicant id here is the Applicant ID not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    initializeApplicantChangeOfProgramme: async (data) => {
        return axios.post(Links.programmeChange.initializeApplicantChangeOfProgramme, data)
    },

    /**
     * For applicant change of programme application
     * @param {object} data The payload for the request, required properties are studentId, reason and targetProgrammeId.
     * The student id here is the Applicant id not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyApplicant: async (data) => {
        return axios.post(Links.programmeChange.applyApplicant, data)
    },

    /**
     * Gets pending change of programme for an applicant
     * @param {string} applicantId Applicant id here is the Applicant id not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPendingForApplicant: async (applicantId) => {
        return axios.get(Links.programmeChange.getPendingForApplicant + '?applicant=' + applicantId)
    },

    /**
     * Gets all change of programmes applied by an applicant
     * @param {string} applicantId Applicant id here is the Applicant id not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAllForApplicant: async (applicantId) => {
        return axios.get(Links.programmeChange.getAllForApplicant + '?applicant=' + applicantId)
    },

    /**
     * Approves change of programme for an applicant
     * @param {string} id database id of the change of programme to approve
     * @returns {Promise<AxiosResponse<any>>}
     */
    approveApplicant: async (id) => {
        return axios.put(Links.programmeChange.approveApplicant + '/' + id)
    },

    /**
     * Disapproves change of programme for an applicant
     * @param {string} id database id of the change of programme to approve
     * @returns {Promise<AxiosResponse<any>>}
     */
    disapproveApplicant: async (id) => {
        return axios.put(Links.programmeChange.disapproveApplicant + '/' + id)
    },

    /**
     * Gets all applications made. this is to be used with pagination
     * @param {string} type the type denotes either for applicants or students. 1 is for applicants
     * @param {string} page the page to get
     * @param {string} size the number of items to fetch
     * @param {string} programmeId the programme to filter by. If not provided, it will return for all programmes
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAll: async (type, page, size, programmeId, programmeTypeId) => {
        return axios.get(Links.programmeChange.getAll + '?type=' + type + '&programme_type=' + programmeTypeId + '&page=' + page + '&size=' + size + (programmeId ? '&programme=' + programmeId : ''))
    },


    /* ************************ new apis *******************************/
    /**
     * @description Gets all students that apply for change of programme. the returned data is a pagination
     * @param {string} type if this is 1, then it returns the applications for students leaving a programme(e.g application for student leaving CMP) otherwise, it returns application for student coming to a programme(e.g application for student coming to CMP).
     * @param {string} page the page to get
     * @param {string} size the number of items to fetch
     * @param {string} programmeId id of the programme to filter by
     * @param {string} sessionId the session of application
     * @returns {Promise<AxiosResponse<any>>} containing paginated data
     */
    getAllForStudents: async (type, page, size, programmeId, sessionId) => {
        return axios.get(Links.programmeChange.getAllStudents + '?type=' + type + '&page=' + page + '&size=' + size + '&session=' + sessionId + (programmeId ? '&programme=' + programmeId : ''))
    },

    /**
     * @description Get pending application made by student
     * @param {string} studentId the Matric (userId) of the student
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPendingForStudent: async (studentId) => {
        return axios.get(Links.programmeChange.getPendingForStudent + '?student=' + studentId)
    },

    /**
     * @description Get all application made by student
     * @param {string} studentId the Matric (userId) of the student
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAllForStudent: async (studentId) => {
        return axios.get(Links.programmeChange.getAllForStudent + '?student=' + studentId)
    },

    /**
     * @description Api for initializing student change of programme transaction
     * @param {object} data The payload for the request. required properties are userId and newProgrammeId.
     * The userId here is the student's matric not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    initializeStudentChangeOfProgramme: async (data) => {
        return axios.post(Links.programmeChange.initializeStudentChangeOfProgramme, data)
    },

    /**
     * @description Api for change of programme for student.
     * when called. the student must make payment before calling this api.
     * Hence, this api will be called in the call back function of the payment api
     * @param {object} data The payload for the request. required properties are studentId,
     * reason and targetProgrammeId.
     * The student id here is matric no. (userId) not database id.
     * @returns {Promise<AxiosResponse<any>>}
     */
    applyStudent: async (data) => {
        return axios.post(Links.programmeChange.applyStudent, data)
    },

    /**
     * @description Disapproves student change of programme. can be called by either the HOD of the leaving department of HOD of the new department
     * @param {string} id the database id of the change of programme model
     * @returns {Promise<AxiosResponse<any>>}
     */
    disapproveStudent: async (id) => {
        return axios.put(Links.programmeChange.disapproveStudent + '/' +id)
    },

    /**
     * @description Approved a student change of programme. can be called by either the HOD of the leaving department of HOD of the new department
     * @param {string} id the database id of the change of programme model
     * @param {object} data the payload of the request with the following props: levelId. level id should be provided only when accepting a student. i.e it is the HOD of the new department that is sending the request. it is basically the level to take a student to when accepting him/her to the new programme
     * @returns {Promise<AxiosResponse<any>>}
     */
    approveStudent: async (id, data) => {
        return axios.put(Links.programmeChange.approveStudent + '/' + id, data)
    },

    /**
     * @description Gets current payment made by student for change of programme
     * @param {string} studentId the userId of the student
     * @returns {Promise<object>}
     */
    getCurrentPayment(studentId){
        return axios.get(Links.programmeChange.getCurrentPayment + '?user=' + studentId)
    },

    /**
     * @description Download invoice for a change of course (.pdf)
     * @param {string} id resource ID of the change of course
     * @returns {Promise<Blob>}
     */
    invoice: async (id) => {
        return axios.get(Links.programmeChange.invoice + '/' + id, {
            responseType: 'blob'
        })
    },

    /**
     * @description Download form for a change of course (.pdf)
     * @param {string} id resource ID of the change of course
     * @returns {Promise<Blob>}
     */
    form: async (id) => {
        return axios.get(Links.programmeChange.form + '/' + id, {
            responseType: 'blob'
        })
    }
}

export default ProgrammeChange
