
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



export default new VueRouter({
    routes: [
        { path: '/profile', component: () =>  import("@/verification/components/ProfileView")},
        { path: '/course', component: () => import("../components/CourseRegistration.vue")},
        { path: '/payment', component: () => import("../components/PaymentDetail.vue")},
    ]
})
