import LoginUtil from "../login/LoginUtil";
import AppTypes from "@/shared/constants/apptypes";

export default {
    async checkErrors(viewInstance, error) {
        console.log(error)

        let other = AppTypes.CURRENT === AppTypes.LAFIA;

        viewInstance.messageType = 'error'
        // console.log(error.response)
        if (error.response) {
            //user is not signed in
            if (error.response.status === 401) {
                if (error.config.url === '/authenticate') {
                    if(other)
                        viewInstance.message = 'User ID / Password incorrect'
                    else
                        viewInstance.message = 'Invalid credentials'
                } else if (error.config.url === '/refresh-token') {
                    window.location.reload()
                } else {
                    if(other)
                        viewInstance.message = 'Session expired, login required';
                    else
                        viewInstance.message = 'Signin required'
                    LoginUtil.resetRefreshToken()
                    LoginUtil.resetAccessToken()
                }

            } else if (error.response.status === 500 && other) { // unauthorized access
                viewInstance.message = 'An error has occurred. Contact the support team'
            } else if (error.response.status === 403) { // unauthorized access
                viewInstance.message = 'Access denied. reload if message persist'
            }
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            else if (error.response.data.error) {
                viewInstance.message = error.response.data.error
            } else if (error.response.data.message) {
                viewInstance.message = error.response.data.message
            } else {
                // check if its a blob
                if (error.response.data instanceof Blob) {
                    // convert to blob
                    await this.toJson(viewInstance, error.response.data)
                    return
                }
                else
                    viewInstance.message = error.response.data;
            }
            // viewInstance.message = "An error has occurred";
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            if(other)
                viewInstance.message = "Error! server is not responding"
            else
                viewInstance.message = "Error! no response from server"
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            viewInstance.message = error.message;
        }

        if (Window.refreshingToken === false)  // refresh token request sent
            viewInstance.messageShow = true;

        Window.refreshingToken = false
    },

    async toJson(vueInstance, err) {
        // eslint
        const r = await err.text()
        const parsed = JSON.parse(r);
        // check
        if (parsed.error) {
            vueInstance.message = parsed.error
        } else {
            vueInstance.message = parsed.message
        }

        vueInstance.messageType = 'error'
        vueInstance.messageShow = true
    },

    async getMessageBlob(error) {

        let other = AppTypes.CURRENT === AppTypes.LAFIA;
        // console.log(error)
        let message = ''
        if (error.response) {
            //user is not signed in
            if (error.response.status === 401) {
                if (error.config.url === '/authenticate') {
                    if(other)
                        message = 'User ID / Password incorrect'
                    else
                        message = 'Invalid credentials'
                } else if (error.config.url === '/refresh-token') {
                    window.location.reload()
                } else {
                    if(other)
                        message = 'Session expired, login required';
                    else
                        message = 'Signin required'
                    LoginUtil.resetRefreshToken()
                    LoginUtil.resetAccessToken()
                }

            } else if (error.response.status === 500 && other) { // unauthorized access
                message = 'An error has occurred. Contact the support team'
            } else if (error.response.status === 403) { // unauthorized access
                message = 'Access denied. reload if message persist'
            }
                // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            else if (error.response.data.error) {
                message = error.response.data.error
            } else if (error.response.data.message) {
                message = error.response.data.message
            } else {
                // check if its a blob
                if (error.response.data instanceof Blob) {
                    // convert to blob
                    message = await this.getMessageJson(error.response.data)
                }
                else
                    message = error.response.data;
            }
            // viewInstance.message = "An error has occurred";
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            if(other)
                message = "Error! server is not responding"
            else
                message = "Error! no response from server"
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            message = error.message;
        }

        return message
    },

    async getMessageJson(err) {
        // eslint
        const r = await err.text()
        const parsed = JSON.parse(r);
        // check
        let message = ''
        if (parsed.error) {
            message = parsed.error
        } else {
            message = parsed.message
        }

        return message
    }
}
