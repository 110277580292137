import axios from "axios";
import Links from "@/shared/constants/Links";


/**
 * SSCE combination for O'LEVEL Results
 */
const SSCECombination = {
    /**
     * ssce subject combination to be created
     * @param {object} data - information to be updated
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.ssceCombination.create, data)
    },

    /**
     * update ssce subject combination
     * @param {string} id - id of utme subject
     * @param {object} data - information to be updated
     * @returns {Promise<object>}
     */
    update: async (id, data) => {
        return axios.put(Links.ssceCombination.update + '/' + id, data)
    },

    /**
     * delete ssce subject combination
     * @param {string} id - utme subject to delete
     * @returns {Promise<object>}
     */
    delete: async (id) => {
        return axios.delete(Links.ssceCombination.delete + '/' + id)
    },

    /**
     * fetch all subject combination for a department
     * @param {string} department - id of the department
     * @returns {Promise<any[]}>
     */
    fetch: async (department) => {
        return axios.get(Links.ssceCombination.fetch + '?department=' + department)
    },

    
}

export default SSCECombination;
