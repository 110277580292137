import axios from "axios";
import Links from "../constants/Links";

/**
 *  This is the Transcript API hook, for handling Transcript (C) 2021-2023
*/

const Transcript = {
  /**
   * @description - fetch the transcript settings
   * @returns {Promise<any[]>}
   */
  getSetting: async () => {
    return axios.get(Links.transcript.getSetting);
  },

  /**
   * @description fetch all modes of delivery
   * @returns {Promise<any[]>}
   */
  getModeofDeliveries: async () => {
    return axios.get(Links.transcript.getmodeDelivery);
  },

  /**
   * @description fetch transcript delivery statuses
   * @returns {Promise<any[]>}
   */
  getDeliveryStatus: async () => {
    return axios.get(Links.transcript.deliveryStatus);
  },

  /**
   * @description update transcript fee
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */
  updateTranscriptFee: async (data) => {
    return axios.post(Links.transcript.updateFee, data);
  },

  /**
   * @description fetch all transcript delivery payment
   * @param {string} modeofdelivery get modeofdelivery id
   * @returns {Promise<any[]>}
   */
  getModeofDeliveryPayment: async (deliverymode) => {
    return axios.get(
      Links.transcript.getModeofDeliveryPayment +
        "?mode_of_delivery=" +
        deliverymode
    );
  },

  /**
   * @description update transcript mode of payment
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */
  updateModeAndPayment: async (data) => {
    return axios.post(Links.transcript.updateModePayment, data);
  },

  /**
   * @description fetch all countries and their prices
   * @param {string} country country id
   * @returns {Promise<any[]>}
   */
  getCountryandPayment: async (country) => {
    return axios.get(
      Links.transcript.countryAndPayment + "?country=" + country
    );
  },

  /**
   * @description update country and their amount
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */
  updateCountryandPayment: async (data) => {
    return axios.post(Links.transcript.updateCountryAndPayment, data);
  },

  /**
   * fetch state and payments
   * @param {string} state stateId
   * @returns {Promise<any[]>}
   */
  getStateAndPayment: async (state) => {
    return axios.get(Links.transcript.getStateAndPayment + "?state=" + state);
  },

  /**
   * @description update state and payment
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */
  updateStateAndPayment: async (data) => {
    return axios.post(Links.transcript.updateStateAndPayment, data);
  },

  /**
   * @description wave session based on each session id
   * @param {object} data information to be updated
   * @returns {Promise<boolean>} true or false as the case may be
   */
  waveSession: async (data) => {
    return axios.post(Links.transcript.waveSession, data);
  },

  /**
   * update the required details such as password, email etc
   * @param {object} data information to be updated
   * @returns {Promise<object>}
   */

  updateAccount: async (data) => {
    return axios.post(Links.transcript.updateAccount, data);
  },


  /**
   * retrive single transcript record base on userId provided
   * @param {string} id ID of the transcript
   * @returns {Promise<any[]>}
   */
  getTranscriptById: async (id) => {
    return axios.get(Links.transcript.getTranscript + "/" + id);
  },


  /**
   * retrive all transcript record base on the userId provided
   * @param {string} userId userId of the student
   * @returns {Promise<any[]>}
   */
  getAlltranscriptRecord: async (userId) => {
    return axios.get(Links.transcript.allTranscript + "?userId=" + userId)
  },

  /**
   * send transcript request with the provided data passed
   * @param {string} userId userid of the student
   * @param {object} data infromation to be updated
   * @returns
  */
  transcriptRequet: async (userId, data) => {
    return axios.post(Links.transcript.makeRequest, { userId, data });
  },

  /**
   * edit user transcript Address
   * @param {string} id id of the delivery
   * @param {object} data information to be updated
   * @returns
   */
  editAddress: async (id, data) => {
    return axios.put(Links.transcript.editAddress + '/' + id, data);
  },

  /**
   * Initiate a transaction, by starting one so the student would pay online using the required third-party service
   * @param {string} requestId
   * @returns {Promise<object>}
   */
  initializeTransaction: async (requestId) => {
    return axios.post(Links.transcript.initializeTransaction, {
      requestId: requestId
    })
  },

  /**
   * Download the invoice generated by the student
   * @param {string} paymentId
   * @returns {Promise<Blob>}
   */
  downloadInvoice: async(paymentId) => {
    return axios.get(Links.transcript.getInvoice + "?payment=" + paymentId, {
      responseType: "blob"
    })
  },

  /**
   * View transcript data base on selected date
   * @param {string} start_date
   * @param {string} end_date
   * @param {string} page the page number
   * @param {string} size number of items to be rendered to the page default 10
   * @returns
   */
  viewByDate: async (start_date, end_date, page, size) => {
    let url = Links.transcript.viewByDate + `?page=${page}&size=${size}`;

    if(start_date != null && end_date != null){
      url = url + `&start_date=${start_date}&end_date=${end_date}`;
    }


    return axios.get(url);
  },

  /**
   * Mark a an already exsiting transcript as processed
   * @param {string} id the selected transcript id
   * @returns {Promise<object>}
   */
  toggleProcessed: async (id) => {
    return axios.put(Links.transcript.toggleProcessed + '/' + id)
  },

  /**
   * Generate transcript for a student
   * @param {number} deliveryId
   * @param {boolean} send
   * @returns {Promise<any>}
   */
  generateTranscript: async (deliveryId, send) => {
    return axios.post(Links.transcript.generateTranscript, {
      deliveryId,
      send
    }, {
      responseType: "blob"
    })
  },

  /**
   * Update the transcript status, statuses differ as REQUEST_SENT, PENDING, REJECTED and ACCEPTED
   * @param {string} content the required content to be updated
   * @returns {Promise<object>}
   */
  updateStatus: async (deliveryId, status) => {
    return axios.post(Links.transcript.updateStatus, {
      deliveryId,
      status
    })
  },

  /**
   * 
   * @param {string} identity 
   * @param {string} reg  matric number of the student
   * @returns {Promise<object>}
   */
  verify: async (identity, reg, address) => {
    return axios.post(Links.transcript.verify, {
      identity,
      reg, 
      address
    })
  },

  /**
   * 
   */
  allAddresses: async (req) => {
    return axios.get(Links.transcript.allAddresses + "?q=" + req)
  },

  /**
   * 
   * @param {string} link 
   * @returns {Promise<Blob>}
   */
  generateVerifiedTranscript: async (link) => {
    return axios.get(Links.transcript.getVerified + `?loc=${link}`, {
      responseType: "blob"
    })
  },

  /**
   * generate transcript for a student 
   * @param {string} studentId 
   * @param {string} startSemester 
   * @param {string} stopSemester 
   * @returns {Promise<Blob>}
   */
  generateTranscriptFromExamOfficer: async (studentId, startSemester, stopSemester) => {
    return axios.post(Links.transcript.generateTranscript, {
      studentId,
      startSemester,
      stopSemester
    })
  }
};

export default Transcript
