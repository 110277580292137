<template>
  <v-dialog v-model="show"  :scrollable="scrollable" :persistent="persistent" :transition="transition" :max-width="maxwidth" content-class="rounded-xl dialog-content"  :fullscreen="fullscreen" retain-focus
    :disabled="disabled">
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: "XDialog",
  props: {
    maxwidth: {
      type: String,
      default: ''
    },

    transition: {
      type: String, 
      default: 'dialog-bottom-transition'
    },

    scrollable: {
      type: Boolean, 
      default: false,
    },

    fullscreen: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.dialog-content{
  border-radius: 16px;
}

.dialog-content >>> .v-card {
  border-radius: 16px !important;
}
</style>
