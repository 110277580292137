import axios from "axios";
import Links from "../constants/Links";


const Support = {
  /**
   * 
   * @param {object} data 
   * @returns {Promise<object>}
   */
  create: async (data) => {
    return axios.post(Links.support.create, data)
  }, 

  /**
   * 
   * @param {string} supportId
   * @param {object} data
   * @returns {Promise<object>}
   */
  update: async (supportId, data) => {
    return axios.put(Links.support.update + '/' + supportId, data)
  },
  
   /**
   * 
   * @param {string} supportId
   * @returns {Promise<object>}
   */
  get: async (supportId) => {
    return axios.get(Links.support.get + supportId)
  },

  /**
   * 
   * @param {string} supportId 
   * @returns {Promise<object>}
   */
  delete: async (supportId) => {
    return axios.delete(Links.support.delete + '/' + supportId)
  }, 

  /**
   * 
   * @returns {Promise<Array>}
   */
  all: async () => {
    return axios.get(Links.support.all); 
  }
}; 


export default Support; 