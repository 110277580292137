import axios from "axios";
import Links from "../constants/Links";

/**
 *  This is the User Management API hook, for handling user management (C) 2021-2023
 */
const User = {
    /**
     * update user information
     * @param {object} data information to be updated
     * @returns {Promise<object>}
     */
    update: async (data) => {
        return axios.put(Links.user.update, data);
    },

    /**
     * fetch all required information for a login user to be displayed on the dashboard
     * @returns {Promise<object>}
     */
    dashboard: async () => {
        return axios.get(Links.user.dashboard)
    },

    /**
     * @description Locks user's account.
     * @param {object} data the ID of the user
     * @returns {Promise<AxiosResponse<*>>} containing the user
     */
    lockAccount: async (data) => {
        return axios.put(Links.user.lockAccount, data)
    },

    /**
     * @description Unlocks user's account.
     * @param {object} data the ID of the user
     * @returns {Promise<AxiosResponse<*>>} containing the user
     */
    unlockAccount: async (data) => {
        return axios.put(Links.user.unlockAccount, data)
    },
}

export default User
