import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Download API hook, for handling downloads
 * (C) 2021-2023
 *  
 * */

const Downloads = {
    /**
     * handle download courseform (.pdf)
     * @param {string} student student id
     * @param {string} session session id
     * @param {string} semester semester id
     * @returns {Promise<Blob>}
     */
    courseForm: async (student, session, semester) => {
        return axios.get(Links.downloads.courseForm + '?session=' + session + '&semester=' + semester + '&student=' + student, {responseType: 'blob'})
    },

    /**
     * handle download exam card
     * @param {string} student student id
     * @param {string} session session id
     * @param {string} semester semester id
     * @returns {Promise<Blob>}
     */
    examCard: async (student, session, semester) => {
        return axios.get(Links.downloads.examCard + '?session=' + session + '&semester=' + semester + '&student=' + student, {responseType: 'blob'})
    },

    /**
     * handle course attendance
     * @param {string} course 
     * @param {string} session 
     * @param {string} semester 
     * @returns {Promise<ArrayBuffer>}
     */
    offeredCourseAttendance: async (course, session, semester) => {
        return axios.get(Links.downloads.offeredCourseAttendance + '?session=' + session + '&semester=' + semester + '&course=' + course, {responseType: 'arraybuffer'})
    },

    /**
     * handle result sheet download (.pdf)
     * @param {string} course course id
     * @param {string} session session id
     * @param {string} semester semester
     * @returns {Promise<Blob>}
     */
    resultReportSheet: async (course, session, semester) => {
        return axios.get(Links.downloads.resultReportSheet + '?session=' + session + '&semester=' + semester + '&course=' + course, {responseType: 'blob'})
    },
}

export default Downloads