<template>
    <v-card-actions aria-label="xcard-actions">
        <slot />
    </v-card-actions>
</template>


<script>
export default {
    name: "XCardAction",
    props: {}
}
</script>